import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
// import { ReactComponent as Planet } from "./assets/planet.svg"
// import { ReactComponent as Astronaut } from "./assets/astronaut.svg"
import "./style.less"
import Particles from "react-particles-js"
import { useTranslation } from "react-i18next"
import { Space, Button } from "antd"
import DucatoLogo from "assets/icons/logo_roundrobin.svg"

const ErrorFallback = ({ title, message }) => {
  const { t } = useTranslation("common")
  const [params, setParams] = useState()

  useEffect(() => {
    setTimeout(() => {
      setParams({
        particles: {
          number: {
            value: 160,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 4,
              size_min: 0.3,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 0.17,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 600,
            },
          },
        },
        retina_detect: true,
      })
    }, [500])
  }, [])

  return (
    <div className="permission_denied">
      <Particles params={params} />
      <Space direction="vertical" className="denied__wrapper">
        <h1>{title}</h1>
        {message ? <h3>{message}</h3> : <h3>{t("Error:404.Message")}</h3>}
        <img src={DucatoLogo} className="rrb-logo" />
        <Button type="link" href="/" className="home-btn">
          {t("GoHome")}
        </Button>
      </Space>
    </div>
  )
}

ErrorFallback.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}

export default ErrorFallback
