import React, { useMemo } from "react"
import { Layout } from "antd"
import "./styles.less"
import PropTypes from "prop-types"
import { useLocation } from "react-router"
import { ROUTE_PATHS } from "const/routes"
// import { useThemeSwitcher } from "react-css-theme-switcher"
// import BouncingBlock from "./components/BouncingBlock"

const ANIMATION_GIF = {
  POOL: "/icons/loading-spinner-pool.gif",
  SWAP: "/icons/loading-spinner-swap.gif",
  DEFAULT: "/icons/loading-spinner-all.gif",
}

const SuspenseFallback = () => {
  const { pathname } = useLocation()
  // const { currentTheme } = useThemeSwitcher()
  const _src = useMemo(() => {
    switch (pathname) {
      case ROUTE_PATHS.POOL:
        return ANIMATION_GIF.POOL
      case ROUTE_PATHS.SWAP:
        return ANIMATION_GIF.SWAP
      default:
        return ANIMATION_GIF.DEFAULT
    }
  }, [pathname])

  return (
    <Layout className="fallback">
      <Layout.Content className="spin_center">
        <img src={_src} className="loading-spinner" />
        {/* {
          currentTheme === "dark" ?
            <BouncingBlock /> // Bouncing block animation for dark theme
            :
            <img src="/icons/loading-spinner.gif" className="loading-spinner" /> // Colored spinner GIF for light theme
        } */}
      </Layout.Content>
    </Layout>
  )
}

SuspenseFallback.propTypes = {
  src: PropTypes.string,
}

export default SuspenseFallback
