export const WORKER_API_ROUTE = {
  SWAP_TRANSACTIONS: "/transactions/swaps",
  DEPOSIT_TRANSACTION: "/transactions/deposits",
  WITHDRAW_TRANSACTION: "/transactions/withdraws",
  PAIRS: "/pairs",
  USER_LIQUIDITY: "/liquidity-position-snapshots",
  TOKENS: "/tokens",
  ANALYSIS: "/charts/day-data",
  OVERVIEW: "/dashboard/overview",
  REWARD_POOL_INFO: "/reward-pool/latest-info",
  STAKING_POOL: "/stake-pool",
  STAKING_POOL_TOKEN: "/stake-pool/tokens",
  STAKING_HISTORY: "/stakes/staking",
  UPDATE_STAKING_REWARD_SNAPSHOT: "/stakes/staking/:id",
  REQUEST_SIGN: "/users/request-sign",
  SIGN_IN_BY_SIGNATURE: "/users/sign-in-by-sig",
  SIGN_IN_BY_AUTH_GATEWAY: "/users/sign-in-by-auth-gateway",
  RE_AUTH: "/users/refresh-token",
  UPDATE_OTP_SECRET: "/users/otp/secret",
  SIGN_IN_BY_OTP: "users/sign-in-by-otp",
  ENQUEUE_UNSTAKE: "/stakes/edit/queue-stake",
  REWARD_CONFIG: "/global-settings/reward/settings",
  FARMING_CONFIG: "farming/all",
  PRICE_HISTORY: "/charts/price-history",
  AIRDROP_PROOF: "/airdrop/:id/proof",
  LIST_PROPOSAL: "/proposal",
  PROPOSAL_DETAIL: "/proposal/:id",
  PROPOSAL_VOTE_DETAIL: "/proposal/vote/:id",
  LIST_AIRDROP: "/drops",
}

export const NFT_REWARD_ROUTE = {
  REDEEM_NFT_CARD: "/roundrobin/nft_reg.do",
  LIST_NFT_STAKING_MERCHANDISE: "/api/staking/products.do",
  GET_NFT_STAKING_MERCHANDISE_DETAIL: "/api/staking/goods.do",
  PURCHASE_NFT_STAKING_MERCHANDISE: "/api/staking/publish_nft.do",
  GET_NFT_STAKING_MERCHANDISE_PURCHASE_HISTORY: "/api/staking/history.do",
  UPDATE_NFT_TRANSACTION: "/api/staking/nft_trans_update.do",
  LIST_IDO_MERCHANDISE: "/api/ido/products.do",
  GET_IDO_MERCHANDISE_DETAIL: "/api/ido/goods.do",
  PURCHASE_IDO_MERCHANDISE: "/api/ido/publish.do",
  GET_IDO_MERCHANDISE_PURCHASE_HISTORY: "/api/ido/history.do",
}

export const ADMIN_API_ROUTE = {
  REQUEST_FEE_PAYER: "/transaction/execute/fee_delegated",
  REQUEST_SIGN_FEE_PAYER: "/transaction/sign/fee_delegated",
}

export const NFT_MARKET_API_ROUTE = {
  SELL_NFT: "/nft/sell",
  BUY_NFT: "/nft/buy",
  CANCEL_NFT_ORDER: "nft/cancel-sell",
  EDIT_NFT_ORDER: "nft/edit-sell",
  SELL_MORE_ORDER: "nft/sellMore",
  GET_MIN_PRICE_NFT: "order/min-price",
  GET_COLLECTION_DETAIL: "collection/detail",
  GET_COLLECTION_STATISTIC: "collection/statistic",
  LIST_COLLECTION: "collection/list",
  GET_OWNER_NFT: "user/nfts",
  LIST_PRESALE_NFT: "nft/pre-sale",
  GET_COLLECTION_CREATOR: "collection/creator",
  LIST_HOT_NFT: "nft/hot",
  GET_NFT_ORDER: "order/owner",
  LIST_NFT: "nft/list",
  LIST_NFT_OWNER: "nft/owners",
  LIST_PAY_TOKEN: "paytoken/list",
  GET_NFT_DETAIL: "nft/detail",
  GET_USER_NFT_FOR_SALE: "order/list",
  LIST_FILTER_CATEGORY: "category/get-filter",
  LIST_HOT_COLLECTION: "/hot-collection",
  GET_PLATFORM_FEE: "get-system/?key=PlatformFee",
  GET_PAY_ORDER: "order/payOrders",
  GET_CRAWLED_BLOCK: "nft/crawled-block",
}

export const PAIR_LIST_SORT_OPTIONS = {
  RESERVE_USD: "reserveUSD",
  VOLUME_USD: "volumeUSD",
}

export const TOKEN_LIST_SORT_OPTIONS = {
  TOTAL_LIQUIDITY: "totalLiquidity",
}
