import { WORKER_API_ROUTE } from "const/api"
import axios from "utils/axios"

export const requestSign = async (address, options) => {
  return axios.post(
    WORKER_API_ROUTE.REQUEST_SIGN,
    {
      address,
    },
    options
  )
}

export const signBySignature = async (message, signature, options) => {
  return axios.post(
    WORKER_API_ROUTE.SIGN_IN_BY_SIGNATURE,
    {
      message: message,
      signature: signature,
    },
    options
  )
}

export const signByAuthGateway = async (tx, options) => {
  return axios.post(
    WORKER_API_ROUTE.SIGN_IN_BY_AUTH_GATEWAY,
    {
      tx: tx,
    },
    options
  )
}

export const reAuthorize = async (token, options) => {
  return axios.get(WORKER_API_ROUTE.RE_AUTH, {
    ...options,
    headers: {
      "x-token": token,
    },
  })
}

export const updateOTPSecret = async (address, secret, token, options) => {
  return await axios.post(
    WORKER_API_ROUTE.UPDATE_OTP_SECRET,
    {
      address,
      secret,
    },
    {
      ...options,
      headers: {
        "x-token": token,
      },
    }
  )
}

export const signByOTP = async (address, otp, options) => {
  return axios.post(
    WORKER_API_ROUTE.SIGN_IN_BY_OTP,
    {
      address,
      otp,
    },
    options
  )
}
