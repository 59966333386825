import { WORKER_API_ROUTE } from "const/api"
import pLimit from "p-limit"
import axios from "utils/axios"
import Web3 from "web3"
import hash from "object-hash"

export const fetchTokenList = async (options = {}) => {
  return await axios.get(WORKER_API_ROUTE.TOKENS, {
    params: options,
  })
}

// Caching purpose
let tokenInfo = {}
const pLimitTokenInfo = pLimit(1)
export const fetchTokenInfo = (token, options) => {
  return pLimitTokenInfo(_fetchTokenInfo, token, options)
}
const _fetchTokenInfo = async (token, options = {}) => {
  if (!Web3.utils.isAddress(token)) throw new Error("Error:InvalidTokenAddress")
  const _token = Web3.utils.toChecksumAddress(token)
  if (!_token) return
  const requestHash = hash({
    token,
    options,
  })
  if (tokenInfo[requestHash] == null) {
    const response = await axios.get(`${WORKER_API_ROUTE.TOKENS}/${_token}`, {
      params: options,
    })
    if (response.status === 200) {
      tokenInfo[requestHash] = {
        timestamp: Date.now(),
        data: response,
      }
    }
    return response
  } else if (Date.now() - tokenInfo[requestHash].timestamp > 60 * 1000) {
    tokenInfo[requestHash] = null
    return await _fetchTokenInfo(_token, options)
  } else {
    return tokenInfo[requestHash].data
  }
}

export const fetchAnalysisData = async (
  startTime = 0,
  perPage = 100,
  options = {}
) => {
  return axios.get(WORKER_API_ROUTE.ANALYSIS, {
    params: {
      ...options,
      perPage,
      startTime,
    },
  })
}

export const fetchAnalysisOverview = async (options = {}) => {
  return axios.get(WORKER_API_ROUTE.OVERVIEW, {
    params: {
      ...options,
    },
  })
}
