// eslint-disable-next-line no-undef
require("./utils/monkey-patch")
import "index.less"
import "utils/i18n"
import { createRoot } from "react-dom/client"
import React, {
  createContext,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from "react"
import SuspenseFallback from "components/SuspenseFallback"
import useAppState, { DISPATCH_TYPE } from "services/hooks/Utils/useAppState"
import Screens from "screens"
import { loadExistWallet } from "services/wallet-providers"
import { ThemeSwitcherProvider } from "react-css-theme-switcher"
import { Modal, notification, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { WALLET_PROVIDERS } from "const/walletProviders"
import { CHAIN_ID } from "const/token"
import { useConnectionStatus } from "services/hooks/Utils/useConnectionStatus"
import { SUPPORTED_LOCALES_OBJECT } from "const/locales"
import { ConfigProvider } from "antd"
import koKR from "antd/lib/locale/ko_KR"
import enUS from "antd/lib/locale/en_US"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "screens/Error"
import { BrowserRouter } from "react-router-dom"
import { Helmet } from "react-helmet"
import { LoadingOutlined } from "@ant-design/icons"
import { CHAIN } from "const/smartContracts"

// eslint-disable-next-line no-undef
require("utils/number")

const themeMap = {
  dark: `${process.env.PUBLIC_URL}/themes/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/themes/light-theme.css`,
}

export const AppContext = createContext()

const App = () => {
  const [app, dispatch] = useAppState()
  const { t, i18n } = useTranslation("common")
  const isOnline = useConnectionStatus()
  const antdLocale = useMemo(() => {
    switch (i18n.language) {
      case SUPPORTED_LOCALES_OBJECT["KO"].value:
        return koKR
      default:
        return enUS
    }
  }, [i18n.language])
  const klaytn = window.klaytn

  useEffect(() => {
    dispatch({
      type: DISPATCH_TYPE.CONNECTION_STATUS,
      payload: isOnline,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline])

  useEffect(() => {
    if (dispatch) {
      ;(async () => {
        dispatch({
          type: DISPATCH_TYPE.CONNECT_WALLET,
          payload: await loadExistWallet(),
        })
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onKaikasWalletChanged = useCallback(
    (accounts) => {
      if (app?.wallet?.address == null || app?.wallet?.address === accounts[0])
        return
      notification.info({
        message: t("WalletChanged.Title"),
        description: t("WalletChanged.Description", {
          address: accounts[0],
        }),
      })
      dispatch({
        type: DISPATCH_TYPE.CONNECT_WALLET,
        payload: {
          provider: WALLET_PROVIDERS.KAIKAS.NAME,
          address: accounts[0],
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [app?.wallet?.address, t]
  )

  const onKaikasNetworkChanged = useCallback(
    (netId) => {
      if (klaytn) {
        Modal.destroyAll()
        if (parseInt(netId || klaytn?.networkVersion) === parseInt(CHAIN_ID))
          return
        Modal.error({
          title: t("NetworkChanged.Title"),
          content: t("NetworkChanged.Description", {
            requiredNetwork: CHAIN_ID === CHAIN.TESTNET ? "Baobab" : "Mainnet",
          }),
          okButtonProps: {
            style: {
              display: "none",
            },
          },
        })
      }
    },
    [klaytn, t]
  )

  useEffect(() => {
    try {
      if (app?.wallet?.provider === WALLET_PROVIDERS.KAIKAS.NAME) {
        if (klaytn) {
          // klaytn.autoRefreshOnNetworkChange = false
          onKaikasNetworkChanged()
          klaytn.on("accountsChanged", onKaikasWalletChanged)
          klaytn.on("networkChanged", onKaikasNetworkChanged)
          return () => {
            klaytn.removeAllListeners()
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
  }, [
    onKaikasWalletChanged,
    onKaikasNetworkChanged,
    klaytn,
    app?.wallet?.provider,
  ])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("MetaData.Title")}</title>
        <meta name="description" content={t("MetaData.Description")} />
      </Helmet>
      <ConfigProvider locale={antdLocale}>
        <AppContext.Provider value={[app, dispatch]}>
          <Screens />
        </AppContext.Provider>
      </ConfigProvider>
    </>
  )
}

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin />)

const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <ThemeSwitcherProvider themeMap={themeMap} defaultTheme="light">
      <Suspense fallback={<SuspenseFallback />}>
        <ErrorBoundary fallback={<ErrorFallback message={"App crashed"} />}>
          <App />
        </ErrorBoundary>
      </Suspense>
    </ThemeSwitcherProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)

export default App
