import { Spin } from "antd"
import React from "react"

import "./style.less"

export default function SpinContainer() {
  return (
    <div className="full-width spin-container">
      <Spin className="margin-auto" />
    </div>
  )
}
