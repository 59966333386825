import { Button, Space, Typography } from "antd"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import "./style.less"

export const CONTACT_EMAIL = "partners@roundrobin.finance"

const Contact = () => {
  const { t } = useTranslation("HomePage")
  const _contact = useCallback(() => {
    window.open(`mailto:${CONTACT_EMAIL}`)
  }, [])

  return (
    <div id="contact-section" className="homepage-section">
      <Space
        className="content-section margin-auto full-width center-text"
        direction="vertical"
        size={20}
      >
        <Typography.Title className="contact-title white-text">
          <span className="no-wrap">{t("Contact.Title.1")}</span>
          &nbsp;
          <span className="no-wrap">{t("Contact.Title.2")}</span>
        </Typography.Title>
        <Typography.Text className="white-text">
          {t("Contact.Description")}
        </Typography.Text>
        <Button
          type="ghost"
          className="contact-email-container"
          size="large"
          onClick={_contact}
        >
          <Typography.Text strong>{CONTACT_EMAIL}</Typography.Text>
        </Button>
      </Space>
    </div>
  )
}

export default Contact
