import { getKlipRequestURL } from "services/wallet-providers"
import SpinContainer from "components/SpinContainer"
import QRCode from "qrcode.react"
import Countdown from "antd/lib/statistic/Countdown"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Space, Typography, Modal, Spin, notification } from "antd"
import { AppContext } from "index"
import React, {
  useContext,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from "react"
import { isMobileOnly, isAndroid } from "react-device-detect"
import { WALLET_PROVIDERS } from "const/walletProviders"
import { request } from "klip-sdk"
import { DISPATCH_TYPE } from "services/hooks/Utils/useAppState"
import { KLIP_REQUEST_STATUS } from "services/hooks/Utils/useKlipResultChecker"
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"

const KlipTransactionModal = () => {
  const [app, dispatch] = useContext(AppContext)
  const { t } = useTranslation("Error")
  const modalVisible = useMemo(() => {
    if (app?.klipCurrentTransaction?.requestKey) return true
    return false
  }, [app?.klipCurrentTransaction?.requestKey])

  const status = useMemo(() => {
    return app?.klipCurrentTransaction?.status
  }, [app?.klipCurrentTransaction?.status])

  const requestKey = useMemo(() => {
    return app?.klipCurrentTransaction?.requestKey
  }, [app?.klipCurrentTransaction?.requestKey])

  const expirationTime = useMemo(() => {
    return app?.klipCurrentTransaction?.expirationTime
  }, [app?.klipCurrentTransaction?.expirationTime])

  const _onCancelTransaction = useCallback(() => {
    if (typeof app?.klipCurrentTransaction?.onCancelTransaction === "function")
      app?.klipCurrentTransaction?.onCancelTransaction()
    dispatch({
      type: DISPATCH_TYPE.CANCEL_KLIP_TRANSACTION,
    })
    notification.error({
      message: t("Error:Error"),
      description: t("Error:USER_REJECT_TRANSACTION"),
    })
  }, [app?.klipCurrentTransaction, dispatch, t])

  return (
    <Modal
      visible={modalVisible}
      onCancel={_onCancelTransaction}
      destroyOnClose
      footer={null}
      className="klip-connect-modal"
    >
      <KlipQR
        reqKey={requestKey}
        expirationTime={expirationTime}
        status={status}
      />
    </Modal>
  )
}

KlipTransactionModal.propTypes = {
  requestKey: PropTypes.string,
  onFinish: PropTypes.func,
  onTimeOut: PropTypes.func,
  onRegen: PropTypes.func,
}

export default KlipTransactionModal

export const KlipQR = ({ reqKey, expirationTime, status, onRegen }) => {
  const { t } = useTranslation()
  const [isTimeOut, setQRTimeOut] = useState(false)

  const isDone = useMemo(
    () => status === KLIP_REQUEST_STATUS.COMPLETED,
    [status]
  )

  const isError = useMemo(() => status === KLIP_REQUEST_STATUS.ERROR, [status])

  const handleKlipConnectOnMobile = useCallback(() => {
    if (reqKey == null) return
    if (isAndroid || isMobileOnly) {
      request(reqKey, () => {
        window.open(getKlipRequestURL(reqKey))
      })
    }
  }, [reqKey])

  useEffect(() => {
    handleKlipConnectOnMobile()
  }, [handleKlipConnectOnMobile])

  if (!reqKey) return <SpinContainer />

  const regen = () => {
    if (typeof onRegen === "function") {
      setQRTimeOut(false)
      onRegen()
    }
  }

  const onTimeOut = () => {
    setQRTimeOut(true)
  }

  if (isMobileOnly)
    return (
      <Space
        direction="vertical"
        className="space-align-center space-full-width"
      >
        <img src={WALLET_PROVIDERS.KLIP.ICON} alt="Klip logo" />
        {isDone ? (
          <Typography.Text style={{ fontSize: "5rem" }}>
            <CheckCircleTwoTone twoToneColor="#00ff86" />
          </Typography.Text>
        ) : isError ? (
          <Typography.Text style={{ fontSize: "5rem" }}>
            <CloseCircleTwoTone twoToneColor="#ff4d4f" />
          </Typography.Text>
        ) : isTimeOut ? (
          <Space>
            <Typography.Text strong type="danger">
              {t("ConnectWallet.Wallet.Klip.TimeOut")}
            </Typography.Text>
            {onRegen != null && typeof onRegen === "function" ? (
              <Typography.Link onClick={regen}>
                {t("ConnectWallet.Wallet.Klip.Regen")}
              </Typography.Link>
            ) : null}
          </Space>
        ) : (
          <Space direction="vertical">
            <Spin />
            <Typography.Link type="danger" onClick={handleKlipConnectOnMobile}>
              {t("ConnectWallet.Wallet.Klip.OpenKlipApp")}
            </Typography.Link>
            {expirationTime ? (
              <Typography.Text>
                {t("ConnectWallet.Wallet.Klip.TimeRemaining")}
                <Countdown
                  value={expirationTime * 1000}
                  format="mm:ss"
                  onFinish={onTimeOut}
                />
              </Typography.Text>
            ) : null}
          </Space>
        )}
      </Space>
    )

  return (
    <Space direction="vertical" className="space-align-center space-full-width">
      <Space>
        <img src={WALLET_PROVIDERS.KLIP.ICON} alt="Klip logo" />
        <Typography.Text strong>
          {t("ConnectWallet.Wallet.Klip.Hint")}
        </Typography.Text>
      </Space>
      <QRCode
        id="klip-qr-container"
        value={getKlipRequestURL(reqKey)}
        size={250}
        includeMargin
        className={isTimeOut || isDone || isError ? "blur" : null}
      />
      {isDone ? (
        <Typography.Text style={{ fontSize: "5rem" }}>
          <CheckCircleTwoTone twoToneColor="#00ff86" />
        </Typography.Text>
      ) : isError ? (
        <Typography.Text style={{ fontSize: "5rem" }}>
          <CloseCircleTwoTone twoToneColor="#ff4d4f" />
        </Typography.Text>
      ) : isTimeOut ? (
        <Space>
          <Typography.Text strong type="danger">
            {t("ConnectWallet.Wallet.Klip.TimeOut")}
          </Typography.Text>
          {onRegen != null && typeof onRegen === "function" ? (
            <Typography.Link onClick={regen}>
              {t("ConnectWallet.Wallet.Klip.Regen")}
            </Typography.Link>
          ) : null}
        </Space>
      ) : (
        <Space direction="vertical">
          {expirationTime ? (
            <Typography.Text>
              {t("ConnectWallet.Wallet.Klip.TimeRemaining")}
              <Countdown
                value={expirationTime * 1000}
                format="mm:ss"
                onFinish={onTimeOut}
              />
            </Typography.Text>
          ) : null}
          <Typography.Text>
            {t("ConnectWallet.Wallet.Klip.Instruction")}
          </Typography.Text>
        </Space>
      )}
    </Space>
  )
}

KlipQR.propTypes = {
  reqKey: PropTypes.string,
  expirationTime: PropTypes.number,
  onRegen: PropTypes.func,
  status: PropTypes.string,
}
