import { WORKER_API_ROUTE } from "const/api"
import axios from "utils/axios"

export const getAirdropList = async (filter, options = {}) => {
  const { token } = filter ?? {}
  return await axios.get(WORKER_API_ROUTE.LIST_AIRDROP, {
    ...options,
    params: {
      token,
    },
  })
}

export const getAirdropClaimMeta = (eventId, user, options = {}) => {
  return axios.get(WORKER_API_ROUTE.AIRDROP_PROOF.replace(":id", eventId), {
    ...options,
    params: {
      user,
    },
  })
}
