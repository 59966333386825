import { DownOutlined } from "@ant-design/icons"
import { Col, Divider, Dropdown, Menu, Row, Space, Typography } from "antd"
import { SUPPORTED_LOCALES_OBJECT } from "const/locales"
import { HELPER_NAVIGATION_MENU } from "const/navigators"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const HelperMenu = () => {
  const { t, i18n } = useTranslation("common")
  const helperMenu = useMemo(
    () =>
      i18n.language === SUPPORTED_LOCALES_OBJECT.KO.value
        ? HELPER_NAVIGATION_MENU.KO
        : HELPER_NAVIGATION_MENU.EN,
    [i18n?.language]
  )

  const HelperMenu = useMemo(
    () => (
      <Menu
        items={helperMenu.subMenu?.map((ele) => ({
          key: ele.title,
          label: ele.directLink ? (
            <a href={ele.path} target="_blank" rel="noreferrer">
              {t(ele.title)}
            </a>
          ) : (
            <Link to={ele.path}>{t(ele.title)}</Link>
          ),
        }))}
      />
    ),
    [helperMenu?.subMenu, t]
  )

  return (
    <Space size={20}>
      <Dropdown overlay={HelperMenu} overlayClassName="helper-menu">
        <Row align="middle" gutter={5}>
          <Col>
            <Typography.Text>{t(helperMenu.title)}</Typography.Text>
          </Col>
          <Col>
            <DownOutlined style={{ fontSize: "0.6rem" }} />
          </Col>
        </Row>
      </Dropdown>
      <Divider type="vertical" />
    </Space>
  )
}

export default HelperMenu
