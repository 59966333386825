/* eslint-disable react/prop-types */
import { Space, Typography } from "antd"
import React, { useEffect, useMemo, useRef } from "react"
import { useCountUp } from "react-countup"
import { removeDecimal } from "utils"
import {
  countDecimal,
  isBNEqualsZero,
  numberWithCommas,
  reduceDecimalToMatch,
  removeInsignificantDecimals,
} from "utils/number"

const CurrencyHolder = ({
  type = "default",
  value,
  decimals = 0,
  duration = 2,
  prefix,
  suffix,
  spaceSize = 5,
  animated = true,
  strong = false,
  withCommas,
  color,
  preAnimation = false,
  numberFontSize,
  ...props
}) => {
  const isUpdate = useRef()
  const _decimals = useMemo(() => {
    if (isNaN(decimals))
      return countDecimal(
        removeInsignificantDecimals(reduceDecimalToMatch(value, decimals))
      )
    return Math.min(
      decimals,
      countDecimal(
        removeInsignificantDecimals(reduceDecimalToMatch(value, decimals))
      )
    )
  }, [decimals, value])
  const { countUp, update } = useCountUp({
    start: !preAnimation && !isUpdate?.current ? value || 0 : 0,
    end: value || 0,
    decimals: decimals,
    duration: duration,
    separator: withCommas ? "," : null,
    // easingFn: (t, b, c, d) => ((t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b) // EaseOutExpo
  })

  const smallestNumber = useMemo(() => {
    if (decimals <= 0) return 0
    return 1 * Math.pow(0.1, decimals)
  }, [decimals])

  const isSmall = useMemo(() => {
    if (smallestNumber > 0) {
      return parseFloat(value) < smallestNumber
    }
  }, [value, smallestNumber])

  const isLarge = useMemo(() => {
    if (isNaN(value)) return false
    return removeDecimal(value).length > 15
  }, [value])

  const isZero = useMemo(() => {
    return isBNEqualsZero(value)
  }, [value])

  useEffect(() => {
    isUpdate.current = false
    update(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  if (isNaN(value))
    return (
      <Typography.Text
        strong={strong}
        code={type === "code"}
        type={type}
        {...props}
        style={{ color: color }}
      >
        <Space size={spaceSize} style={{ alignItems: "baseline" }}>
          {prefix ? (
            <Typography.Text
              className={type === "color" ? "accent-text" : null}
              style={{ color: color }}
              type={type}
              {...props}
            >
              {prefix}
            </Typography.Text>
          ) : null}
          <Typography.Text
            className={
              type === "color" || type === "alt-color" ? "accent-text" : null
            }
            style={{ color: color, fontSize: numberFontSize }}
            type={type}
            {...props}
          >
            -
          </Typography.Text>
          {suffix ? (
            <Typography.Text
              className={type === "color" ? "accent-text" : null}
              style={{ color: color }}
              type={type}
              {...props}
            >
              {suffix}
            </Typography.Text>
          ) : null}
        </Space>
      </Typography.Text>
    )

  return (
    <Typography.Text
      strong={strong}
      code={type === "code"}
      type={type}
      {...props}
      style={{ color: color }}
    >
      <Space size={spaceSize} style={{ alignItems: "baseline" }}>
        {!isZero && isSmall ? "<" : null}
        {prefix ? (
          <Typography.Text
            className={type === "color" ? "accent-text" : null}
            style={{ color: color }}
            type={type}
            {...props}
          >
            {prefix}
          </Typography.Text>
        ) : null}
        <Typography.Text
          className={
            type === "color" || type === "alt-color" ? "accent-text" : null
          }
          style={{ color: color, fontSize: numberFontSize }}
          type={type}
          {...props}
        >
          {isZero
            ? 0
            : isSmall
            ? smallestNumber.toFixed(decimals)
            : isLarge
            ? parseFloat(value).toFixed(_decimals)
            : animated
            ? reduceDecimalToMatch(countUp, _decimals)
            : withCommas
            ? numberWithCommas(reduceDecimalToMatch(value, _decimals))
            : reduceDecimalToMatch(value, _decimals)}
        </Typography.Text>
        {suffix ? (
          <Typography.Text
            className={type === "color" ? "accent-text" : null}
            style={{ color: color }}
            type={type}
            {...props}
          >
            {suffix}
          </Typography.Text>
        ) : null}
      </Space>
    </Typography.Text>
  )
}

export default CurrencyHolder
