import React, { useContext, useEffect, useState } from "react"
import { Button, notification, Space, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { AppContext } from "index"
import PropTypes from "prop-types"
import ConnectWalletModal from "./components/ConnectWallet"
import KlipTransactionModal from "components/KlipTransactionModal"
import { useHash } from "react-use"
import { ACTION_HASH } from "const/routes"
import { useLocation } from "react-router"
import {
  KLIP_CONNECTION_TIMEOUT,
  WALLET_PROVIDERS,
} from "const/walletProviders"
import { DISPATCH_TYPE } from "services/hooks/Utils/useAppState"
import { useBigScreen } from "utils/responsive"
import Icon from "@ant-design/icons"
import { ReactComponent as WalletIcon } from "assets/icons/ic-wallet.svg"
import { maskWalletAddress } from "utils"

const WalletIndicator = () => {
  const normalView = useBigScreen(640)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { t } = useTranslation()
  const [walletProps, dispatch] = useContext(AppContext)
  const location = useLocation()
  const [, setHash] = useHash()

  useEffect(() => {
    if (location.hash === `#${ACTION_HASH.CONNECT_WALLET}`) {
      setIsModalVisible(true)
    } else {
      setIsModalVisible(false)
    }
  }, [location])

  // const toggleModal = () => {
  //   setHash(isModalVisible ? null : ACTION_HASH.CONNECT_WALLET)
  // }

  useEffect(() => {
    if (walletProps?.wallet?.provider === WALLET_PROVIDERS.KLIP.NAME) {
      const _timeout = setTimeout(() => {
        notification.warning({
          description: t("ConnectWallet.Wallet.Klip.ExpiredConnection"),
        })
        dispatch({
          type: DISPATCH_TYPE.KLIP_WALLET_EXPIRED,
        })
      }, [KLIP_CONNECTION_TIMEOUT])
      return () => clearTimeout(_timeout)
    }
  }, [
    dispatch,
    t,
    walletProps?.wallet?.lastTransaction,
    walletProps?.wallet?.provider,
  ])

  const handleCancel = () => {
    setHash("")
  }

  const handleOpenModal = () => {
    setHash(ACTION_HASH.CONNECT_WALLET)
  }

  return (
    <>
      <KlipTransactionModal />
      <ConnectWalletModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
      <Space size={normalView ? 30 : 0}>
        {/* {
          walletProps.wallet ?
            <AccountHolder onClickAssets={onClickAssets} wallet={walletProps.wallet} />
            :
            <AccountHolder onClickAssets={handleOpenModal} wallet={walletProps.wallet} />
        } */}

        {normalView ? (
          <Button
            onClick={handleOpenModal}
            className={
              walletProps?.wallet
                ? "connect-wallet-btn connected"
                : "connect-wallet-btn"
            }
          >
            <Typography.Text strong>
              {walletProps?.wallet
                ? maskWalletAddress(walletProps.wallet.address)
                : t("ConnectWallet.Title")}
            </Typography.Text>
          </Button>
        ) : (
          <Button
            type="ghost"
            icon={
              <Icon component={WalletIcon} style={{ fontSize: "1.5rem" }} />
            }
            onClick={handleOpenModal}
          ></Button>
        )}
      </Space>
    </>
  )
}

WalletIndicator.propTypes = {
  onClickAssets: PropTypes.func,
}

export default WalletIndicator
