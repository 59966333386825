import { WORKER_API_ROUTE } from "const/api"
import pLimit from "p-limit"
import axios, { axiosOld } from "utils/axios"
import hash from "object-hash"

const QUERY_LIMIT = 100

export const fetchPairList = async (options = {}) => {
  return await axios.get(WORKER_API_ROUTE.PAIRS, {
    params: options,
  })
}

let pairInfo = {}
const pLimitPairInfo = pLimit(20)
export const fetchPairInfo = (address, options) => {
  return pLimitPairInfo(_fetchPairInfo, address, options)
}
const _fetchPairInfo = async (address, options) => {
  const requestHash = hash({
    address,
    options,
  })
  if (pairInfo[requestHash] == null) {
    const response = await wrappedFetchPairInfo(address, options)
    if (response.status === 200) {
      pairInfo[requestHash] = {
        timestamp: Date.now(),
        data: response,
      }
    }
    return response
  } else if (Date.now() - pairInfo[requestHash].timestamp > 60 * 1000) {
    pairInfo[requestHash] = null
    return await _fetchPairInfo(address, options)
  } else {
    return pairInfo[requestHash].data
  }
}
const wrappedFetchPairInfo = async (address, options = {}) => {
  const lpv1 = options?.lpv1

  if (lpv1 === true)
    return await axiosOld.get(`${WORKER_API_ROUTE.PAIRS}/${address}`, {
      params: options,
    })

  const response1 = await axios.get(`${WORKER_API_ROUTE.PAIRS}/${address}`, {
    params: options,
  })

  if (response1.status === 200) return response1

  const response2 = await axiosOld.get(`${WORKER_API_ROUTE.PAIRS}/${address}`, {
    params: options,
  })

  if (response2.status === 200) return response2

  return response1
}

// export const fetchPairInfo = async (address, options = {}) => {
//   const lpv1 = options?.lpv1

//   if (lpv1 === true)
//     return await axiosOld.get(`${WORKER_API_ROUTE.PAIRS}/${address}`, {
//       params: options,
//     })

//   const response1 = await axios.get(`${WORKER_API_ROUTE.PAIRS}/${address}`, {
//     params: options,
//   })

//   if (response1.status === 200) return response1

//   const response2 = await axiosOld.get(`${WORKER_API_ROUTE.PAIRS}/${address}`, {
//     params: options,
//   })

//   if (response2.status === 200) return response2

//   return response1
// }

export const fetchUserLiquidity = async (
  address,
  pairAddress,
  options = {}
) => {
  return await axios.get(`${WORKER_API_ROUTE.USER_LIQUIDITY}/${address}`, {
    params: {
      ...options,
      perPage: QUERY_LIMIT,
      pairAddress,
    },
  })
}

export const fetchDepositTransactionHistory = async (
  userAddress,
  pairAddress,
  options = {}
) => {
  return axios.get(`${WORKER_API_ROUTE.DEPOSIT_TRANSACTION}`, {
    params: {
      ...options,
      pairAddress,
      userAddress,
      perPage: QUERY_LIMIT,
    },
  })
}
export const fetchWithdrawTransactionHistory = async (
  userAddress,
  pairAddress,
  options = {}
) => {
  return axios.get(`${WORKER_API_ROUTE.WITHDRAW_TRANSACTION}`, {
    params: {
      ...options,
      pairAddress,
      userAddress,
      perPage: QUERY_LIMIT,
    },
  })
}

// Caching purpose
let rewardPoolInfo
const pLimitRewardPoolInfo = pLimit(1)
export const fetchRewardPoolInfo = async (options = {}) => {
  return pLimitRewardPoolInfo(_fetchRewardPoolInfo, options)
}
const _fetchRewardPoolInfo = async (options = {}) => {
  if (rewardPoolInfo == null) {
    const response = await axios.get(WORKER_API_ROUTE.REWARD_POOL_INFO, {
      ...options,
    })
    if (response.status === 200)
      rewardPoolInfo = {
        timestamp: Date.now(),
        data: response,
      }
    return response
  } else if (Date.now() - rewardPoolInfo.timestamp > 60 * 1000) {
    rewardPoolInfo = null
    return await _fetchRewardPoolInfo(options)
  } else {
    return rewardPoolInfo.data
  }
}
