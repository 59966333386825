import { NFT_REWARD_ROUTE } from "const/api"
import { nftAxios } from "utils/axios"

export const redeemNFTCard = async (address, eventID, options = {}) => {
  return nftAxios.get(NFT_REWARD_ROUTE.REDEEM_NFT_CARD, {
    params: {
      WALLET_ADDR: address,
      NFT_KEY: eventID,
    },
    ...options,
  })
}

export const listNFTStakingMerchandise = async (options = {}) => {
  return nftAxios.get(NFT_REWARD_ROUTE.LIST_NFT_STAKING_MERCHANDISE, {
    ...options,
  })
}

export const getNFTStakingMerchandiseDetail = async (seq, options = {}) => {
  return nftAxios.get(NFT_REWARD_ROUTE.GET_NFT_STAKING_MERCHANDISE_DETAIL, {
    params: {
      seq: seq,
    },
    ...options,
  })
}

export const buyNFTStakingMerchandise = async (
  seq,
  buyer,
  txHash,
  options = {}
) => {
  return nftAxios.get(NFT_REWARD_ROUTE.PURCHASE_NFT_STAKING_MERCHANDISE, {
    params: {
      seq: seq,
      wallet_addr: buyer,
      txHash: txHash,
    },
    ...options,
  })
}

export const getNFTStakingMerchandisePurchaseHistory = async (
  wallet_addr,
  options = {}
) => {
  return nftAxios.get(
    NFT_REWARD_ROUTE.GET_NFT_STAKING_MERCHANDISE_PURCHASE_HISTORY,
    {
      params: {
        wallet_addr: wallet_addr,
      },
      ...options,
    }
  )
}

export const updateNFTStakingMerchandisePurchaseHistory = async (
  contractAddress,
  tokenId,
  options = {}
) => {
  return nftAxios.get(NFT_REWARD_ROUTE.UPDATE_NFT_TRANSACTION, {
    params: {
      contract: contractAddress,
      token_id: tokenId,
    },
    ...options,
  })
}
