/* eslint-disable react/prop-types */
import { Button, Dropdown, Menu, Typography } from "antd"
import { SUPPORTED_LOCALES } from "const/locales"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { DownOutlined, GlobalOutlined } from "@ant-design/icons"

const LanguageSelector = () => {
  const { i18n } = useTranslation()

  const handleSelect = useCallback(
    (locale) => {
      i18n.changeLanguage(locale.value)
    },
    [i18n]
  )

  const menu = useMemo(
    () => (
      <Menu className="language-menu">
        {SUPPORTED_LOCALES.map((locale) => (
          <Menu.Item key={locale.value} onClick={() => handleSelect(locale)}>
            {locale.title}
          </Menu.Item>
        ))}
      </Menu>
    ),
    [handleSelect]
  )

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        type="ghost"
        style={{ fontWeight: "bold" }}
        className="language-selector-btn"
      >
        <GlobalOutlined />
        <Typography.Text>
          {SUPPORTED_LOCALES.find((ele) => ele.value === i18n.language)?.title}
        </Typography.Text>
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default LanguageSelector
