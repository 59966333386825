import KlayIcon from "assets/icons/ic-klay.svg"
import KlipIcon from "assets/icons/ic-klip.svg"
import KaikasWalletConnect from "components/Header/components/WalletIndicator/components/KaikasWalletConnect"
import KlipWalletConnect from "components/Header/components/WalletIndicator/components/KlipWalletConnect"

export const WALLET_PROVIDERS = {
  KAIKAS: {
    NAME: "Kaikas",
    INSTALL_URL:
      "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi",
    TITLE: "ConnectWallet.Modal.Kaikas",
    ICON: KlayIcon,
    DETAIL_COMPONENT: KaikasWalletConnect,
  },
  KLIP: {
    NAME: "Klip",
    INSTALL_URL: "",
    TITLE: "ConnectWallet.Modal.Klip",
    ICON: KlipIcon,
    DETAIL_COMPONENT: KlipWalletConnect,
    TIME_OUT: 5 * 60 * 1000,
  },
}

export const KLIP_CONNECTION_TIMEOUT =
  window.CONFIG?.KLIP_CONNECTION_TIMEOUT ?? 10 * 60 * 1000 // 10 minutes

export const DISABLED_WALLET_PROVIDER =
  window.CONFIG?.DISABLED_WALLET_PROVIDER ?? []
