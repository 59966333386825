import { AES, enc } from "crypto-js"
import KIP37Token from "smartcontracts/KIP37Token.json"
import KIP17Token from "smartcontracts/KIP17Token.json"
import ROUTER_ABI from "smartcontracts/DucatoRouter.json"
import FACTORY_ABI from "smartcontracts/DucatoFactory.json"
import REWARD_POOL_ABI from "smartcontracts/DucatoRewardPool.json"
import REWARD_HUB_ABI from "smartcontracts/DucatoRewardHub.json"
import {
  SMART_CONTRACT_ADDRESS,
  SMART_CONTRACT_ADDRESS_OLD,
} from "const/smartContracts"

export const maskWalletAddress = (address) => {
  if (address == null) return ""
  const first = address.substring(0, 5)
  const last = address.substr(address.length - 5)
  return `${first}-${last}`
}

export const encryptAddress = (address) => {
  if (!address) return
  const prefix = address.substring(0, 5)
  return prefix + AES.encrypt(address, prefix)
}

export const decryptAddress = (encrypted) => {
  if (!encrypted) return
  const prefix = encrypted.substring(0, 5)
  return AES.decrypt(encrypted.substring(5), prefix).toString(enc.Utf8)
}

export const convertArrayToObject = (array, key, prefunc) => {
  try {
    const initialValue = {}
    return array.reduce((obj, item) => {
      const _key =
        typeof prefunc === "function" ? prefunc(item[key]) : item[key]
      return {
        ...obj,
        [_key]: item,
      }
    }, initialValue)
  } catch (err) {
    return {}
  }
}

export const extractInfoSwapTransaction = (transaction) => {
  const { pairInfo } = transaction
  if (transaction?.amount0In != 0 && transaction?.amount1Out != 0)
    return [
      {
        value: transaction?.amount0In,
        token: pairInfo?.tokens[0],
      },
      {
        value: transaction?.amount1Out,
        token: pairInfo?.tokens[1],
      },
    ]
  if (transaction?.amount1In != 0 && transaction?.amount0Out != 0)
    return [
      {
        value: transaction?.amount1In,
        token: pairInfo?.tokens[1],
      },
      {
        value: transaction?.amount0Out,
        token: pairInfo?.tokens[0],
      },
    ]
  return [undefined, undefined]
}

export const wrappingSwapTransactions = (array) => {
  // Wrap same hash
  const initialValue = {}
  const wrappedTransactions = array.reduce((obj, item) => {
    const { timestamp, txid, from } = item
    const hash = item.txid

    if (obj[hash]) {
      obj[hash].hops.push(item)
      return obj
    }

    return {
      ...obj,
      [hash]: {
        timestamp,
        txid,
        from,
        hash,
        hops: [item],
      },
    }
  }, initialValue)

  // Sorting hops
  return Object.values(wrappedTransactions).map((transaction) => {
    const { hops: _hops } = transaction

    const { sortedHops: hops, route } = extractSwapRoute(_hops)
    const [input, output] = extractInOut(hops)

    return {
      ...transaction,
      route,
      hops,
      input,
      output,
    }
  })
}

const extractSwapRoute = (hops) => {
  try {
    const [startToken, endToken] = extractInOut(hops)
    if (!startToken || !endToken) throw new Error("INVALID_HOPS")

    const result = hops.reduce(
      (result) => {
        const currentHop = hops.find(
          (hop) =>
            extractInfoSwapTransaction(hop)[0].token.address ===
            result.currentToken.address
        )
        if (!currentHop) throw new Error("MISSING_HOP")
        const hopInfo = extractInfoSwapTransaction(currentHop)

        return {
          route: [...result.route, hopInfo[1]],
          sorted: [...result.sorted, currentHop],
          currentToken: hopInfo[1].token,
        }
      },
      {
        route: [startToken],
        sorted: [],
        currentToken: startToken.token,
      }
    )

    return {
      sortedHops: result.sorted,
      route: result.route,
    }
  } catch (err) {
    // console.error(err)
    return {
      sortedHops: hops.sort(safeCompareHashIndex),
      routeError: err,
    }
  }
}

export const extractInOut = (hops) => {
  if (hops.length === 1) return extractInfoSwapTransaction(hops[0])
  const hopsInfo = hops.map(extractInfoSwapTransaction)
  const inputTokens = hopsInfo.map((ele) => ele[0])
  const outputTokens = hopsInfo.map((ele) => ele[1])

  try {
    let startToken, endToken
    const uniqueStartToken = inputTokens.filter(
      (input) =>
        !outputTokens
          .map((ele) => ele.token.address)
          .includes(input.token.address)
    )
    const uniqueEndToken = outputTokens.filter(
      (output) =>
        !inputTokens
          .map((ele) => ele.token.address)
          .includes(output.token.address)
    )

    if (uniqueStartToken.length === 1) startToken = uniqueStartToken[0]
    else throw new Error("Can't find start token")
    if (uniqueEndToken.length === 1) endToken = uniqueEndToken[0]
    else throw new Error("Can't find end token")
    return [startToken, endToken]
  } catch (err) {
    return [hopsInfo[0][0], hopsInfo[hopsInfo.length - 1][1]]
  }
}

export const genDaysArray = (start, end) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt))
  }
  return arr
}

export const getMethodABI = (smartContractAddress, methodName) => {
  const scABI = getABIFromSmartContractAddress(smartContractAddress)
  const methodABI = scABI.find((ele) => ele.name === methodName)
  if (!methodABI) throw new Error("UNKNOWN_SMART_CONTRACT_METHOD_NAME")
  return methodABI
}

export const getABIFromSmartContractAddress = (smartContractAddress) => {
  switch (smartContractAddress) {
    case SMART_CONTRACT_ADDRESS.FACTORY:
    case SMART_CONTRACT_ADDRESS_OLD.FACTORY:
      return FACTORY_ABI.abi
    case SMART_CONTRACT_ADDRESS.ROUTER:
    case SMART_CONTRACT_ADDRESS_OLD.ROUTER:
      return ROUTER_ABI.abi
    case SMART_CONTRACT_ADDRESS_OLD.REWARD_POOL:
      return REWARD_POOL_ABI.abi
    case SMART_CONTRACT_ADDRESS.REWARD_HUB:
      return REWARD_HUB_ABI.abi
    default:
      throw new Error("UNKNOWN_SMART_CONTRACT_ADDRESS")
  }
}

export const getMethodNftABI = (contractType, methodName) => {
  const scABI = getNftABIFromSmartContractType(contractType)
  const methodABI = scABI.find((ele) => ele.name === methodName)
  if (!methodABI) throw new Error("UNKNOWN_SMART_CONTRACT_METHOD_NAME")
  return methodABI
}
export const getNftABIFromSmartContractType = (contractType) => {
  switch (contractType) {
    case "NFT_KIP37":
      return KIP37Token.abi
    case "NFT_KIP17":
      return KIP17Token.abi
    default:
      throw new Error("UNKNOWN_SMART_CONTRACT_ADDRESS")
  }
}
export const preventPopUp = (id, checked) => {
  if (checked)
    localStorage.setItem(`prevent_popup_${id}`, new Date().toDateString())
  else localStorage.removeItem(`prevent_popup_${id}`)
}

export const isPreventPopUp = (id) => {
  const last = localStorage.getItem(`prevent_popup_${id}`)
  if (new Date().toDateString() === last) return true
  return false
}

export const stripWrapToken = (token) => {
  if (!token) return
  const { symbol, wrapOf } = token
  if (typeof wrapOf === "string") return wrapOf
  if (typeof wrapOf === "object") return wrapOf.symbol
  if (!symbol) return
  switch (symbol) {
    case "RRT":
    case "KSP":
    case "KLAY":
      return symbol
  }
  if (symbol.startsWith("K")) return symbol.slice(1)
  if (symbol.startsWith("RR")) return symbol.slice(2)
  return symbol
}

export const sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

export const removeDecimal = (_value) => {
  if (_value == null) return
  const value = `${_value}`
  const [integer] = value.split(".")
  return integer
}

const safeCompareHashIndex = (a, b) => {
  try {
    return parseInt(a.hash?.split?.("-")[1]) - parseInt(b.hash?.split?.("-")[1])
  } catch (err) {
    return 0
  }
}
// shorten address
export const shortenAddress = (string, lengthShortCut, mask = "...") => {
  if (string == null || string == undefined) return ""
  return string.slice(0, lengthShortCut) + mask + string.slice(-lengthShortCut)
}
// timestamp to date
export const convertTimeStamp = (timestamp) => {
  const date = new Date(timestamp)
  const hours = date.getHours()
  const dd = String(date.getDate()).padStart(2, "0")
  const mm = String(date.getMonth() + 1).padStart(2, "0")
  const yyyy = date.getFullYear()
  const minutes = "0" + date.getMinutes()
  return hours + ":" + minutes.substr(-2) + "," + dd + "/" + mm + "/" + yyyy
}
export const genIntArray = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)
}

export const safeExecute = async (func, defaultReturns) => {
  try {
    return await func()
  } catch (err) {
    return defaultReturns
  }
}

export const removeParamsFromPath = (path) => {
  if (path == null) return
  if (`${path}`.includes(":"))
    return removeParamsFromPath(path.slice(0, path.lastIndexOf("/")))
  else return path
}
