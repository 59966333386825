/* eslint-disable no-unused-vars */
import { CopyOutlined } from "@ant-design/icons"
import { Button, Col, Row, Space, Tooltip, Typography } from "antd"
import SetupOTPWallet from "components/Header/components/WalletIndicator/components/SetupOTPWallet"
import { AppContext } from "index"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { maskWalletAddress } from "utils"

const AccountWallet = () => {
  const { t } = useTranslation()
  const [walletProps] = useContext(AppContext)

  const onCopyAddress = () => {
    navigator.clipboard.writeText(walletProps.wallet.address)
  }

  if (walletProps?.wallet?.address == null) return null

  return (
    <Space
      id="connect-wallet-modal"
      direction="vertical"
      className="space-align-center space-full-width"
    >
      <Row align="middle" gutter={[20, 20]}>
        <Col flex={1}>
          <Tooltip title={t("Copied")} trigger={["click"]}>
            <Button
              onClick={onCopyAddress}
              size="large"
              className="wallet-address-container full-width"
            >
              <Space>
                <Typography.Text>
                  {maskWalletAddress(walletProps.wallet.address)}
                </Typography.Text>
                <CopyOutlined />
              </Space>
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <SetupOTPWallet />
        </Col>
      </Row>
    </Space>
  )
}

export default AccountWallet
