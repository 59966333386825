import { convertArrayToObject } from "utils"

export const SUPPORTED_LOCALES = [
  {
    value: "en-US",
    title: "EN",
  },
  {
    value: "ko-KR",
    title: "KO",
  },
]

export const SUPPORTED_LOCALES_OBJECT = convertArrayToObject(
  SUPPORTED_LOCALES,
  "title"
)
