import { Menu, Space, Typography } from "antd"
import { SUPPORTED_LOCALES_OBJECT } from "const/locales"
import { HELPER_NAVIGATION_MENU, NAVIGATOR_MENU } from "const/navigators"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

const NavigationMenu = ({
  mode = "horizontal",
  justify = "start",
  onSelect,
  withHelper,
}) => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigatorMenu = useMemo(
    () =>
      i18n.language === SUPPORTED_LOCALES_OBJECT.KO.value
        ? NAVIGATOR_MENU.KO
        : NAVIGATOR_MENU.EN,
    [i18n.language]
  )

  const helperMenu = useMemo(
    () =>
      i18n.language === SUPPORTED_LOCALES_OBJECT.KO.value
        ? HELPER_NAVIGATION_MENU.KO
        : HELPER_NAVIGATION_MENU.EN,
    [i18n.language]
  )

  const convert2MenuItem = useCallback(
    (nav) => {
      try {
        if (nav.subMenu)
          return {
            key: nav.title,
            icon: nav.icon,
            children: nav.subMenu.map(convert2MenuItem),
            label: (
              <Space size={5}>
                {nav.subMenu
                  .map((ele) => ele.path)
                  .includes(location.pathname) ? (
                  <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                    &bull;
                  </Typography.Text>
                ) : null}
                <Typography.Text
                  strong={nav.subMenu
                    .map((ele) => ele.path)
                    .includes(location.pathname)}
                >
                  {t(nav.title)}
                </Typography.Text>
              </Space>
            ),
          }
        return {
          key: nav.title,
          icon: nav.icon,
          label: nav.directLink ? (
            <a href={nav.path} target="_blank" rel="noreferrer">
              <Space size={5}>
                {nav.path === location.pathname ? (
                  <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                    &bull;
                  </Typography.Text>
                ) : null}
                <Typography.Text strong={nav.path === location.pathname}>
                  {t(nav.title)}
                </Typography.Text>
              </Space>
            </a>
          ) : (
            <Link to={nav.path}>
              <Space size={5}>
                {nav.path === location.pathname ? (
                  <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                    &bull;
                  </Typography.Text>
                ) : null}
                <Typography.Text strong={nav.path === location.pathname}>
                  {t(nav.title)}
                </Typography.Text>
              </Space>
            </Link>
          ),
        }
      } catch (err) {
        return
      }
    },
    [location, t]
  )

  const navigationMenuItems = useMemo(
    () =>
      (withHelper ? [...navigatorMenu, helperMenu] : navigatorMenu)
        .map(convert2MenuItem)
        .filter((ele) => ele != null),
    [convert2MenuItem, helperMenu, navigatorMenu, withHelper]
  )

  return (
    <Menu
      selectedKeys={[location.pathname]}
      mode={mode}
      style={{
        backgroundColor: "transparent",
        justifyContent: justify,
      }}
      items={navigationMenuItems}
      onSelect={onSelect}
    />
  )
}

NavigationMenu.propTypes = {
  mode: PropTypes.string,
  justify: PropTypes.string,
  onSelect: PropTypes.func,
  withHelper: PropTypes.bool,
}

const MenuItem = ({ item }) => {
  const { t } = useTranslation("common")

  if (item.subMenu)
    return (
      <Menu.SubMenu
        title={
          <Space size={5}>
            {item.subMenu.map((ele) => ele.path).includes(location.pathname) ? (
              <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                &bull;
              </Typography.Text>
            ) : null}
            <Typography.Text
              strong={item.subMenu
                .map((ele) => ele.path)
                .includes(location.pathname)}
            >
              {t(item.title)}
            </Typography.Text>
          </Space>
        }
        key={`submenu-${item.title}`}
      >
        {item.subMenu.map((nav) => (
          <MenuItem key={nav.title} item={nav} />
        ))}
      </Menu.SubMenu>
    )
  return (
    <Menu.Item key={item.path}>
      {item.directLink ? (
        <a href={item.path} target="_blank" rel="noreferrer">
          <Space size={5}>
            {item.path === location.pathname ? (
              <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                &bull;
              </Typography.Text>
            ) : null}
            <Typography.Text strong={item.path === location.pathname}>
              {t(item.title)}
            </Typography.Text>
          </Space>
        </a>
      ) : (
        <Link to={item.path}>
          <Space size={5}>
            {item.path === location.pathname ? (
              <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                &bull;
              </Typography.Text>
            ) : null}
            <Typography.Text strong={item.path === location.pathname}>
              {t(item.title)}
            </Typography.Text>
          </Space>
        </Link>
      )}
    </Menu.Item>
  )
}

MenuItem.propTypes = {
  item: PropTypes,
}

export default NavigationMenu
