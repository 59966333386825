import React, { lazy } from "react"
import { Redirect } from "react-router-dom"

const HomePage = lazy(() => import("screens/Home"))
const AnalyticsPage = lazy(() => import("screens/Analytics"))
const PoolPage = lazy(() => import("screens/Pool"))
const PoolPageOld = lazy(() => import("screens/PoolOld"))
// const NFTStorePage = lazy(() => import("screens/NFTStore"))
// const IDOStorePage = lazy(() => import("screens/IDOStore"))
const StakingPage = lazy(() => import("screens/Staking"))
const StakingPoolPage = lazy(() => import("screens/StakingPool"))
const StakingPoolDetail = lazy(() => import("screens/StakingPoolDetail"))
const SwapPage = lazy(() => import("screens/Swap"))
const PoolDetail = lazy(() => import("screens/PoolDetail"))
const PoolOldDetail = lazy(() => import("screens/PoolOldDetail"))
const UserAssets = lazy(() => import("screens/UserAssets"))
const ErrorBoundaries = lazy(() => import("screens/Error"))
const VotingPool = lazy(() => import("screens/VotingPool"))
const NFTLandingPage = lazy(() =>
  import("screens/NFTMarket/components/NFTLandingPage")
)
const NFTCollectionsPage = lazy(() =>
  import("screens/NFTMarket/components/NFTCollections")
)
const NFTCollectionDetail = lazy(() =>
  import("screens/NFTMarket/components/NFTCollectionDetail")
)
const NFTDetail721 = lazy(() =>
  import("screens/NFTMarket/components/NFTDetail721")
)
const NFTDetail1155 = lazy(() =>
  import("screens/NFTMarket/components/NFTDetail1155")
)

const NFTMarketAll = lazy(() =>
  import("screens/NFTMarket/components/NFTMarketAll")
)
const PriceChart = lazy(() => import("screens/PriceChart"))
const VotingProposal = lazy(() => import("screens/VotingProposal"))
const VotingProposalDetail = lazy(() => import("screens/VotingProposalDetail"))
const AirdropsPage = lazy(() => import("screens/Airdrops"))

export const ROUTE_PATHS = {
  HOME: "/",
  SWAP: "/swap",
  STAKING: "/staking",
  STAKING_POOL: "/staking-pool",
  STAKING_POOL_DETAIL: "/staking-pool/:id",
  VOTING_POOL: "/voting-pool",
  VOTING_PROPOSAL: "/voting-proposal",
  VOTING_PROPOSAL_DETAIL: "/voting-proposal/:id",
  NFT_STORE: "/nft-store",
  IDO_STORE: "/ido-store",
  POOL: "/pool",
  POOL_OLD: "/pool-v1",
  ANALYTICS: "/analytics",
  LEARN: "/learn",
  POOL_DETAIL: "/pool/:id",
  POOL_OLD_DETAIL: "/pool-v1/:id",
  ASSETS: "/assets",
  ASSETS_TABS: "/assets/:tabKey",
  NFT_MARKET: "/nft-market",
  NFT_DETAIL721: "/nft/detail/erc721/:collectionAddress/:tokenId",
  NFT_DETAIL1155: "/nft/detail/erc1155/:collectionAddress/:tokenId",
  NFT_COLLECTION: "/nft-collections",
  NFT_COLLECTION_DETAIL: "/nft-collection/detail/:collectionAddress",
  NFT_MARKET_ALL: "/nft-market-all",
  PRICE_CHART: "/price-chart",
  AIRDROPS: "/airdrops",
  NOT_FOUND: "/404",
  OTHERS: "*",
}

export const ROUTES = [
  { path: ROUTE_PATHS.HOME, component: HomePage },
  { path: ROUTE_PATHS.SWAP, component: SwapPage },
  // { path: ROUTE_PATHS.NFT_STORE, component: NFTStorePage },
  // { path: ROUTE_PATHS.IDO_STORE, component: IDOStorePage },
  { path: ROUTE_PATHS.STAKING, component: StakingPage },
  { path: ROUTE_PATHS.STAKING_POOL, component: StakingPoolPage },
  { path: ROUTE_PATHS.STAKING_POOL_DETAIL, component: StakingPoolDetail },
  { path: ROUTE_PATHS.VOTING_POOL, component: VotingPool },
  { path: ROUTE_PATHS.VOTING_PROPOSAL, component: VotingProposal },
  { path: ROUTE_PATHS.VOTING_PROPOSAL_DETAIL, component: VotingProposalDetail },
  { path: ROUTE_PATHS.POOL, component: PoolPage },
  { path: ROUTE_PATHS.POOL_OLD, component: PoolPageOld },
  { path: ROUTE_PATHS.ANALYTICS, component: AnalyticsPage },
  { path: ROUTE_PATHS.POOL_DETAIL, component: PoolDetail },
  { path: ROUTE_PATHS.POOL_OLD_DETAIL, component: PoolOldDetail },
  { path: ROUTE_PATHS.ASSETS, component: UserAssets },
  { path: ROUTE_PATHS.ASSETS_TABS, component: UserAssets },
  { path: ROUTE_PATHS.NFT_MARKET, component: NFTLandingPage },
  { path: ROUTE_PATHS.NFT_DETAIL721, component: NFTDetail721 },
  { path: ROUTE_PATHS.NFT_DETAIL1155, component: NFTDetail1155 },
  { path: ROUTE_PATHS.NFT_MARKET_ALL, component: NFTMarketAll },
  { path: ROUTE_PATHS.NFT_COLLECTION, component: NFTCollectionsPage },
  { path: ROUTE_PATHS.NFT_COLLECTION_DETAIL, component: NFTCollectionDetail },
  { path: ROUTE_PATHS.NOT_FOUND, component: ErrorBoundaries },
  { path: ROUTE_PATHS.PRICE_CHART, component: PriceChart },
  { path: ROUTE_PATHS.AIRDROPS, component: AirdropsPage },
  { path: ROUTE_PATHS.NOT_FOUND, component: ErrorBoundaries },
  // eslint-disable-next-line react/react-in-jsx-scope
  {
    path: ROUTE_PATHS.OTHERS,
    component: () => <Redirect to={ROUTE_PATHS.NOT_FOUND} />,
  },
]

export const ACTION_HASH = {
  CONNECT_WALLET: "connect-wallet",
}

export const ORBIT_CHAIN_ENDPOINT = "https://bridge.orbitchain.io/"

export const KLAYTN_SCOPE_ENDPOINT = {
  CYPRESS: "https://scope.klaytn.com",
  BAOBAB: "https://baobab.scope.klaytn.com",
}
