const runMonkeyPatchForGoogleTranslate = () => {
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        console.error(
          "Cannot remove a child from a different parent",
          child,
          this
        )
        for (let node of this.childNodes) {
          originalRemoveChild.apply(this, [node])
        }
        return child
      }
      return originalRemoveChild.apply(this, [child])
    }

    const originalInsertBefore = Node.prototype.insertBefore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        console.error(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this
        )
        return newNode
      }
      return originalInsertBefore.apply(this, [newNode, referenceNode])
    }
  }
}

runMonkeyPatchForGoogleTranslate()
