import { Percent } from "@uniswap/sdk-core"
import JSBI from "jsbi"

export const SWAP_MODES = {
  PREFER_FROM: "PREFER_FROM",
  PREFER_TO: "PREFER_TO",
}

export const MAX_HOP = 3

export const TRANSACTION_DEADLINE =
  window.CONFIG?.TRANSACTION_DEADLINE ?? 30 * 1000 // 30 seconds

export const THIRTY_BIPS_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000))
export const ONE_HUNDRED_PERCENT = new Percent(
  JSBI.BigInt(10000),
  JSBI.BigInt(10000)
)
export const INPUT_FRACTION_AFTER_FEE =
  ONE_HUNDRED_PERCENT.subtract(THIRTY_BIPS_FEE)
export const FIXED_SWAP_FEE = "0.003"

export const MAX_GAS = 1000000

export const TRANSACTION_TYPE = {
  SWAP: "swap",
  DEPOSIT: "deposit",
  WITHDRAW: "withdraw",
}

export const TIME_WINDOW_LIMIT = 24 * 60 * 60 // 1 day

export const ALLOW_FEE_DELEGATION =
  window.CONFIG?.ALLOW_FEE_DELEGATION ??
  process.env.REACT_APP_ALLOW_FEE_DELEGATION === "true"

export const ALLOW_TRANSFER_FEE_DELEGATION =
  window.CONFIG?.ALLOW_TRANSFER_FEE_DELEGATION ?? false
