import { useMediaQuery } from "react-responsive"

export const useCustomScreen = (width) => {
  return useMediaQuery({ query: `(max-width: ${width}px)` })
}

export const useBigScreen = (width = 1366) => {
  return useMediaQuery({ query: `(min-width: ${width}px)` })
}
export const useMediumScreen = (width = 480) => {
  return useMediaQuery({ query: `(min-width: ${width}px)` })
}

export const usePortrait = () => {
  return useMediaQuery({ query: "(orientation: portrait)" })
}
