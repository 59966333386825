import jwtDecode from "jwt-decode"
import Web3 from "web3"
import moment from "moment"

const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
}

export const storeAuthorization = ({ accessToken, refreshToken }) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, accessToken)
  localStorage.setItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN, refreshToken)
}

export const getSavedAuthorization = () => {
  try {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN)

    if (accessToken == null || refreshToken == null)
      throw new Error("NOT_AUTHORIZED")

    return {
      accessToken,
      refreshToken,
    }
  } catch (err) {
    clearAuthorization()
    return
  }
}

export const clearAuthorization = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
  localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN)
}

export const checkTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token)
    const { exp } = decoded
    return moment().isAfter(moment(parseInt(exp) * 1000))
  } catch (err) {
    return true
  }
}

export const checkTokenOwner = (token, expectWalletAddress) => {
  try {
    const decoded = jwtDecode(token)
    const owner = Web3.utils.toChecksumAddress(decoded.address)
    const _address = Web3.utils.toChecksumAddress(expectWalletAddress)
    return owner === _address
  } catch (err) {
    return false
  }
}
