import { WORKER_API_ROUTE } from "const/api"
import axios from "utils/axios"

const QUERY_LIMIT = 100

export const fetchSwapHistory = async (address, options = {}) => {
  return await axios.get(WORKER_API_ROUTE.SWAP_TRANSACTIONS, {
    params: {
      ...options,
      userAddress: address,
      perPage: QUERY_LIMIT,
    },
  })
}
