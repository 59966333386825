import Header from "components/Header"
import LostConnectionModal from "components/LostConnectionModal"
import PolicyRoute from "components/PolicyRoute"
import ScrollToTop from "components/ScrollToTop"
import SuspenseFallback from "components/SuspenseFallback"
import { ROUTES } from "const/routes"
import React, { Suspense } from "react"
import { Switch } from "react-router-dom"
import useCaver from "services/hooks/Blockchain/useCaver"

const Screens = () => {
  const [caver] = useCaver(5)

  if (!caver) return <SuspenseFallback />

  return (
    <>
      <ScrollToTop />
      <Header />
      <Suspense fallback={<SuspenseFallback />}>
        <Switch>
          {ROUTES.map((route) => (
            <PolicyRoute
              key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </Suspense>
      <LostConnectionModal />
    </>
  )
}

export default Screens
