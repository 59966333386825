import { BackTop, Button } from "antd"
import { useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"
import React from "react"
import { UpCircleOutlined } from "@ant-design/icons"

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [pathname])

  return (
    <BackTop target={() => window}>
      <Button
        icon={<UpCircleOutlined />}
        size="large"
        type="primary"
        shape="circle"
        className="elevated-card"
      />
    </BackTop>
  )
}
