import { SMART_CONTRACT_ADDRESS } from "const/smartContracts"
import { AppContext } from "index"
import { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  reAuthorize,
  requestSign,
  signByAuthGateway,
  signBySignature,
} from "services/apis"
import { useKlipWalletProvider } from "services/wallet-providers/klip"
import AuthGatewayABI from "smartcontracts/AuthGateway.json"
import { WALLET_PROVIDERS } from "const/walletProviders"
import {
  clearAuthorization,
  storeAuthorization,
  getSavedAuthorization,
  checkTokenOwner,
  checkTokenExpired,
} from "utils/auth"
import useCaver from "services/hooks/Blockchain/useCaver"

const useAuthorization = () => {
  const { t } = useTranslation("Error")
  const [caver] = useCaver()
  const klaytn = useMemo(() => window.klaytn, [])
  const [walletProps] = useContext(AppContext)
  const { executeContractWithMethodABI } = useKlipWalletProvider()

  const kaikasRequestAuthorization = useCallback(async () => {
    if (!caver || !klaytn) return
    const requestSignResp = await requestSign(walletProps?.wallet?.address)
    const sign = requestSignResp.data.token
    const message = ["WALLET_SIGN", `(${sign})`].join(" ")
    const signature = await caver.rpc.klay.sign(
      walletProps?.wallet?.address,
      message
    )
    const signInResult = await signBySignature(message, signature)
    const { accessToken, refreshToken } = signInResult.data
    storeAuthorization({
      accessToken,
      refreshToken,
      walletAddress: walletProps?.wallet?.address,
    })
    return {
      accessToken,
      refreshToken,
    }
  }, [caver, klaytn, walletProps?.wallet?.address])

  const klipRequestAuthorization = useCallback(async () => {
    if (!caver) return
    const requestSignResp = await requestSign(walletProps?.wallet?.address)
    const sign = requestSignResp.data.token
    const message = ["WALLET_SIGN", `(${sign})`].join(" ")
    const authGatewayInstance = new caver.contract(
      AuthGatewayABI.abi,
      SMART_CONTRACT_ADDRESS.AUTH_GATEWAY
    )
    const methodABI = authGatewayInstance._jsonInterface.find(
      (ele) => ele.name === "auth"
    )
    const response = await executeContractWithMethodABI(
      SMART_CONTRACT_ADDRESS.VS_POOL,
      methodABI,
      {
        from: walletProps?.wallet?.address,
        params: [message],
      }
    )
    const tx = response.txHash || response.tx_hash
    const signInResult = await signByAuthGateway(tx)
    const { accessToken, refreshToken } = signInResult.data
    storeAuthorization({
      accessToken,
      refreshToken,
      walletAddress: walletProps?.wallet?.address,
    })
    return {
      accessToken,
      refreshToken,
    }
  }, [caver, executeContractWithMethodABI, walletProps?.wallet?.address])

  const requestNewAuthorization = useCallback(async () => {
    switch (walletProps?.wallet?.provider) {
      case WALLET_PROVIDERS.KAIKAS.NAME:
        if (klaytn?.selectedAddress !== walletProps?.wallet?.address)
          throw new Error(t("Error:WALLET_MISMATCH"))
        if ((await klaytn.enable())[0] !== walletProps?.wallet?.address)
          throw new Error(t("Error:KAIKAS_WALLET_LINK_ERROR"))
        return await kaikasRequestAuthorization()
      case WALLET_PROVIDERS.KLIP.NAME:
        return await klipRequestAuthorization()
      default:
        throw new Error(t("Error:InvalidWallet"))
    }
  }, [
    kaikasRequestAuthorization,
    klaytn,
    klipRequestAuthorization,
    t,
    walletProps?.wallet?.address,
    walletProps?.wallet?.provider,
  ])

  const requestNewAuthorizationWithRefreshToken = useCallback(async (old) => {
    const { refreshToken: _refreshToken, walletAddress } = old

    const result = await reAuthorize(_refreshToken)
    const { accessToken, refreshToken } = result.data

    if (accessToken == null || refreshToken == null)
      throw new Error("RE_AUTH_FAILED")

    const newAuthorization = {
      accessToken,
      refreshToken,
      walletAddress,
    }

    storeAuthorization(newAuthorization)
    return newAuthorization
  }, [])

  const requestAuthorization = useCallback(async () => {
    try {
      const currentUser = walletProps?.wallet?.address
      const saved = getSavedAuthorization()
      if (saved == null) throw new Error("NOT_AUTHORIZED")
      if (
        !checkTokenOwner(saved.accessToken, currentUser) ||
        !checkTokenOwner(saved.refreshToken, currentUser)
      )
        throw new Error("TOKEN_OWNER_MISMATCH")
      if (!checkTokenExpired(saved.accessToken)) return saved
      if (checkTokenExpired(saved.refreshToken))
        throw new Error("REFRESH_TOKEN_EXPIRED")
      return requestNewAuthorizationWithRefreshToken(saved)
    } catch (err) {
      clearAuthorization()
      return requestNewAuthorization()
    }
  }, [
    requestNewAuthorization,
    requestNewAuthorizationWithRefreshToken,
    walletProps?.wallet?.address,
  ])

  return {
    requestAuthorization,
    clearAuthorization,
  }
}

export default useAuthorization
