/* eslint-disable react/jsx-key */
import { SelectOutlined } from "@ant-design/icons"
import { Col, Divider, Popover, Row, Space, Typography } from "antd"
import React, { useMemo } from "react"
import StatusIndicator from "react-status-indicator"
import useENStatus from "services/hooks/Blockchain/useENStatus"
import { ENDPOINT_NODE_STATUS } from "const/status"
import CurrencyHolder from "components/CurrencyHolder"
import { useTranslation } from "react-i18next"
import { KLAYTN_SCOPE_ENDPOINT } from "const/routes"
import { CHAIN_ID } from "const/token"
import PropTypes from "prop-types"
import { CHAIN } from "const/smartContracts"

const SystemIndicator = ({ type = "normal" }) => {
  const { t } = useTranslation("common")
  const { status, currentBlockNumber } = useENStatus({ interval: 2 })

  const [indicator, details] = useMemo(() => {
    switch (status) {
      case ENDPOINT_NODE_STATUS.NORMAL:
        return [
          <StatusIndicator Positive Pulse />,
          <Wrapper>
            <Space
              className="center-indicator full-width"
              direction="vertical"
              size={20}
            >
              <StatusIndicator Positive Pulse />
              <Space direction="vertical" size="small">
                <Typography.Text type="success">
                  {t("Header.SystemIndicator.Status.Normally.Short")}
                </Typography.Text>
                <Typography.Text strong>
                  {t("Header.SystemIndicator.Status.Normally.Detail")}
                </Typography.Text>
              </Space>
            </Space>
          </Wrapper>,
        ]
      case ENDPOINT_NODE_STATUS.SLOW_SYNC:
        return [
          <StatusIndicator Intermediary Pulse />,
          <Wrapper>
            <Space
              className="center-indicator full-width"
              direction="vertical"
              size={20}
            >
              <StatusIndicator Intermediary Pulse />
              <Space direction="vertical" size="small">
                <Typography.Text type="warning">
                  {t("Header.SystemIndicator.Status.Delayed.Short")}
                </Typography.Text>
                <Typography.Text strong>
                  {t("Header.SystemIndicator.Status.Delayed.Detail")}
                </Typography.Text>
              </Space>
            </Space>
          </Wrapper>,
        ]
      case ENDPOINT_NODE_STATUS.DOWN:
        return [
          <StatusIndicator Negative Pulse />,
          <Wrapper>
            <Space
              className="center-indicator full-width"
              direction="vertical"
              size={20}
            >
              <StatusIndicator Negative Pulse />
              <Space direction="vertical" size="small">
                <Typography.Text type="danger">
                  {t("Header.SystemIndicator.Status.Down.Short")}
                </Typography.Text>
                <Typography.Text strong>
                  {t("Header.SystemIndicator.Status.Down.Detail")}
                </Typography.Text>
              </Space>
            </Space>
          </Wrapper>,
        ]
      default:
        return [
          <StatusIndicator Pulse />,
          <Wrapper>
            <Space
              className="center-indicator full-width"
              direction="vertical"
              size={20}
            >
              <StatusIndicator Pulse />
              <Typography.Text strong>
                {t("Header.SystemIndicator.Status.Checking.Detail")}
              </Typography.Text>
            </Space>
          </Wrapper>,
        ]
    }
  }, [status, t])

  return (
    <Popover
      content={details}
      trigger="click"
      placement="bottom"
      getPopupContainer={() =>
        type === "normal"
          ? document.getElementById("header")
          : document.getElementById("main-menu-drawer")
      }
    >
      <Space size={10} style={{ cursor: "pointer" }}>
        {indicator}
        <Typography.Text>mainnet</Typography.Text>
        <CurrencyHolder
          value={currentBlockNumber}
          prefix="#"
          duration={2}
          withCommas
          spaceSize={0}
        />
      </Space>
    </Popover>
  )
}

SystemIndicator.propTypes = {
  type: PropTypes.string,
}

export default SystemIndicator

const Wrapper = (props) => {
  const { t } = useTranslation()

  return (
    <Space
      id="system-status-detail-container"
      style={{ width: 300 }}
      size={5}
      direction="vertical"
    >
      <Row justify="space-between">
        <Col>
          <Typography.Text strong>Klaytn Network</Typography.Text>
        </Col>
        <Col>
          <Typography.Link
            href={
              CHAIN_ID === CHAIN.TESTNET
                ? KLAYTN_SCOPE_ENDPOINT.BAOBAB
                : KLAYTN_SCOPE_ENDPOINT.CYPRESS
            }
            target="_blank"
          >
            Klaytn Scope &nbsp;
            <SelectOutlined size="small" />
          </Typography.Link>
        </Col>
      </Row>
      {props.children}
      <Divider />
      <Typography.Text>
        {t("Header.SystemIndicator.Description")}
      </Typography.Text>
    </Space>
  )
}

Wrapper.propTypes = {
  children: PropTypes.any,
}
