import React, { useContext, useState } from "react"
import { WALLET_PROVIDERS } from "const/walletProviders"
import { useCallback, useEffect } from "react"
import { Space, Typography } from "antd"
import { klipConnect } from "services/wallet-providers"
import useKlipResultChecker, {
  KLIP_REQUEST_STATUS,
} from "services/hooks/Utils/useKlipResultChecker"
import { DISPATCH_TYPE } from "services/hooks/Utils/useAppState"
import { AppContext } from "index"
import { KlipQR } from "components/KlipTransactionModal/index"
import { useHash } from "react-use"

const KlipWalletConnect = () => {
  const [error, setError] = useState()
  const [reqKey, setReqKey] = useState()
  const [, dispatcher] = useContext(AppContext)
  const [, setHash] = useHash()

  const {
    result,
    setReqKey: setCheckerKey,
    status,
    expirationTime,
  } = useKlipResultChecker()

  useEffect(() => {
    if (reqKey) setCheckerKey(reqKey)
  }, [reqKey, setCheckerKey])

  useEffect(() => {
    if (status === KLIP_REQUEST_STATUS.COMPLETED && result?.klaytn_address) {
      const walletAddress = result.klaytn_address
      // saveKlipWallet(walletAddress)
      setHash("")
      dispatcher({
        type: DISPATCH_TYPE.CONNECT_WALLET,
        payload: {
          provider: WALLET_PROVIDERS.KLIP.NAME,
          address: walletAddress,
          lastTransaction: Date.now(),
        },
      })
    }
  }, [status, result, setHash, dispatcher])

  const klipConnectHandler = useCallback(async () => {
    setReqKey()
    setError()
    const res = await klipConnect()
    if (res.err) {
      return setError(res.err)
    }
    setReqKey(res.request_key)
  }, [])

  useEffect(() => {
    klipConnectHandler()
  }, [klipConnectHandler])

  return (
    <Space
      id="connect-wallet-modal"
      direction="vertical"
      className="space-align-center space-full-width"
    >
      {error ? (
        <Typography.Text type="danger">{error}</Typography.Text>
      ) : (
        <KlipQR
          reqKey={reqKey}
          expirationTime={expirationTime}
          onRegen={klipConnectHandler}
        />
      )}
    </Space>
  )
}

export default KlipWalletConnect
