import { Alert, Modal, Typography } from "antd"
import { AppContext } from "index"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"

const LostConnectionModal = () => {
  const [context] = useContext(AppContext)
  const { t } = useTranslation("common")

  return (
    <Modal
      visible={context.isOnline === false}
      title={null}
      footer={null}
      closable={false}
    >
      <Alert
        message={
          <Typography.Text type="danger">
            {t("OfflineNotification")}
          </Typography.Text>
        }
        type="error"
        showIcon
      />
    </Modal>
  )
}

export default LostConnectionModal
