import Icon from "@ant-design/icons"
import { Button, Spin } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useThemeSwitcher } from "react-css-theme-switcher"
import { ReactComponent as Sun } from "assets/icons/ic-sun.svg"
import { ReactComponent as Moon } from "assets/icons/ic-moon.svg"

const DarkModeSwitch = () => {
  const { switcher, themes, status } = useThemeSwitcher()
  const savedTheme = useMemo(() => localStorage.getItem("theme"), [])
  const [isDarkMode, setIsDarkMode] = useState(savedTheme === "dark")

  useEffect(() => {
    switcher({ theme: isDarkMode ? themes.dark : themes.light })
    localStorage.setItem("theme", isDarkMode ? "dark" : "light")
  }, [isDarkMode, switcher, themes.dark, themes.light])

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode)
  }

  return (
    <Button onClick={toggleTheme} type="ghost" className="theme-selector-btn">
      {status === "loading" ? (
        <Spin indicator={<Icon component={isDarkMode ? Moon : Sun} spin />} />
      ) : (
        <Icon component={isDarkMode ? Moon : Sun} />
      )}
    </Button>
  )
}

export default DarkModeSwitch
