import { ENDPOINT_NODE_STATUS } from "const/status"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useCaver from "services/hooks/Blockchain/useCaver"

const REFRESH_RATE = 2000 // ms

const REQUEST_TIMEOUT = 10000 // ms

/**
 * @param {number=} interval - Somebody's name.
 */

const useENStatus = ({ interval }) => {
  const [status, setStatus] = useState()
  const [currentBlockNumber, setCurrentBlockNumber] = useState(0)
  const [error, setError] = useState()
  const [caver, mkClient] = useCaver()
  const { t } = useTranslation("Error")

  const getCurrentBlockNumber = useCallback(async () => {
    try {
      return caver.utils.hexToNumber(await caver.rpc.klay.getBlockNumber())
    } catch (err) {
      return
    }
  }, [caver])

  const fetchStatus = useCallback(async () => {
    const timeout = setTimeout(async () => {
      setError(t("Error:CAVER_INIT"))
      setStatus(ENDPOINT_NODE_STATUS.DOWN)
    }, REQUEST_TIMEOUT)
    try {
      setError()
      if (!caver) {
        throw new Error(t("Error:CAVER_INIT"))
      }

      // Get the number of the most recent block.
      const currentBlock = await getCurrentBlockNumber()
      if (currentBlock == null) throw new Error(t("Error:CAVER_INIT"))
      setCurrentBlockNumber(currentBlock)

      // Check sync stats.
      const isSyncing = await caver.rpc.klay.isSyncing()
      if (isSyncing === false) {
        setStatus(ENDPOINT_NODE_STATUS.NORMAL)
      } else {
        setStatus(ENDPOINT_NODE_STATUS.SLOW_SYNC)
      }
    } catch (err) {
      setError(err)
      setStatus(ENDPOINT_NODE_STATUS.DOWN)
    }
    clearTimeout(timeout)
  }, [caver, getCurrentBlockNumber, t])

  useEffect(() => {
    fetchStatus()
    if (interval) {
      const loop = setInterval(fetchStatus, interval * 1000 || REFRESH_RATE)
      return () => {
        clearInterval(loop)
      }
    }
  }, [fetchStatus, interval])

  useEffect(() => {
    if (status === ENDPOINT_NODE_STATUS.DOWN) {
      const loop = setInterval(mkClient, REQUEST_TIMEOUT)
      return () => {
        clearInterval(loop)
      }
    }
  }, [mkClient, status])

  useEffect(() => {
    window.addEventListener("focus", fetchStatus)
    return () => {
      window.removeEventListener("focus", fetchStatus)
    }
  }, [fetchStatus])

  return {
    status,
    currentBlockNumber,
    getCurrentBlockNumber,
    error,
  }
}

export default useENStatus
