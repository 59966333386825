export const SMART_CONTRACT_ADDRESS = window.CONFIG?.SMART_CONTRACT_ADDRESS ?? {
  FACTORY: "0xB2c45b8391Cb3D677e4C1Ca62b90CBb456BcA30D",
  FACTORY_UTILS: "0x1b458a134751eA0383c25b65E652EE8E90359950",
  ROUTER: "0x347D066Ee8038b7E462bf4270454A69De1Db292e",
  ZAP_IN: "0x160A47D4962d8Ccc93c955fbafdA559534cD7349",
  TREASURE: "0xfb9ABFfc70Fdb1f7F62ce6656802dA315A67a30f",
  TREASURE_UTILS: "0x5F64A44BE64A03733012B6Af404D6ACc3F091760",
  WKLAY: "0xF49E708ECF8b916112be360BB45aE894ae24B12F",
  LP_MIGRATOR: "0xB60D95786C425c8301AeB364B25f379D938149e6",
  REWARD_HUB: "0x909A92482F2f0575058D09CB5d77e04EA1fA9EE7",
  REWARD_POOL_CONFIG: "0xc0a7bd9276C22DDe1bCd36B18E88c425B31b2D79",
  NFT: [
    "0x1747fe894615749a4d71cbfef953aa1fbf52d64a",
    "0x9e7f6f72d32d97d08342cc3380ed00ee2f3333bf",
    "0xcb7cf6cf033b0a52e03cce404d0178ded54fd531",
    "0x6e952a623bfb35a042ddafb22a312647cf43a27f",
  ],
  BATCH_PROCESS: "0x7a17Fd9803e3bfc28d4C0Ad7ed8117d7F739c82c",
  AUTH_GATEWAY: "0x4f32c2755a8fB6F588b37BE55dC6A173fD103Fd4",
  PERIOD_PROVIDER: "0x3Bf4f85e5Fd72D238F83b66ea2c8f201F2787333",
  VS_POOL: "0x2B9bc1562d0BbCf17E52c9Ef0EE1BfE943B25259",
  VS_POOL_VOTING: "0x4022d80a404Bb5f49EC0Bc973cd6245e2981924d",
  AIRDROP: "0x6Fa4E55F0596633A684Ea3712E3e2a38Fd845ed1",
  DUCATO_PP_GOVERNOR: "0x53c8f21B1e095a722FcF8F0C0EF6f4BD8DA3C390",
  GOVERNANCE_SETTINGS: "0x4c7246EdA58941aD5de1D5Ce25b5B16607774EBC",
  NFT_MARKET: "0x78e391D25Ef54e0CdFB29f9F90de022212E1f4a5",
  NFT_FEE_ADDRESS: "0x261aac2783e16ffd5244d3c5da5b3dc0b9b6e905",
}

export const SMART_CONTRACT_ADDRESS_OLD = window.CONFIG
  ?.SMART_CONTRACT_ADDRESS_OLD ?? {
  FACTORY: "0x876AbE356b3b5196058f26DfA105f399b05F863a",
  REWARD_POOL: "0xAC20Fce8c7DBFf5D13E78265C5e32F5c120aE77d",
  REWARD_POOL_CONFIG: "0xc0a7bd9276C22DDe1bCd36B18E88c425B31b2D79",
  ROUTER: "0x5EdCB112DAAB5129D9306b7C11aBed9BEA851fAB",
  WKLAY: "0xF49E708ECF8b916112be360BB45aE894ae24B12F",
}

export const ENDPOINT_NODE_ADDRESS =
  window.CONFIG?.ENDPOINT_NODE_ADDRESS ??
  process.env.REACT_APP_ENDPOINT_NODE_ADDRESS

export const FEE_PAYER_ADDRESS =
  window.CONFIG?.FEE_PAYER_ADDRESS ??
  "0x1F9C5C8999355e4D9Ef7660eFF04e0dCA4a2F8C9"

export const SMART_CONTRACT_EXECUTION_TYPE = {
  SMART_CONTRACT_EXECUTION: "SMART_CONTRACT_EXECUTION",
  FEE_DELEGATED_SMART_CONTRACT_EXECUTION:
    "FEE_DELEGATED_SMART_CONTRACT_EXECUTION",
  VALUE_TRANSFER: "VALUE_TRANSFER",
  FEE_DELEGATED_VALUE_TRANSFER: "FEE_DELEGATED_VALUE_TRANSFER",
}

export const HIDE_POOL_MODE = {
  ALL: "HIDE_ALL",
  EMPTY: "HIDE_EMPTY",
  VISIBLE: "NO_HIDE",
}

export const CURRENT_HIDE_POOL_MODE =
  HIDE_POOL_MODE[window.CONFIG?.HIDE_POOL_MODE] ?? HIDE_POOL_MODE.EMPTY

export const CHAIN = {
  MAINNET: 8217,
  TESTNET: 1001,
}
