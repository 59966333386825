import { SettingOutlined } from "@ant-design/icons"
import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Space,
  Tooltip,
  Typography,
  Input,
  Form,
} from "antd"
import QRCode from "qrcode.react"
import { authenticator } from "otplib"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { updateOTPSecret } from "services/apis"
import useAuthorization from "services/hooks/Authorization/useAuthorization"
import SpinContainer from "components/SpinContainer"
import { AppContext } from "index"
import PropTypes from "prop-types"

const SetupOTPWallet = () => {
  const { t } = useTranslation("common")
  const [context] = useContext(AppContext)
  const { requestAuthorization } = useAuthorization()
  const [visible, setVisible] = useState(false)

  const showModal = useCallback(() => {
    setVisible(true)
  }, [])

  const hideModal = useCallback(() => {
    setVisible(false)
  }, [])

  const onRegisterOTP = useCallback(
    async (secret) => {
      try {
        if (context?.wallet?.address == null) return
        const token = await requestAuthorization()
        const { accessToken } = token
        const resp = await updateOTPSecret(
          context.wallet.address,
          secret,
          accessToken
        )
        if (resp.status !== 201) throw new Error("Error:UPDATE_OTP_FAILED")

        notification.success({
          message: t("Header.SetupOTP.Success.Title"),
        })
        hideModal()
      } catch (err) {
        notification.error({
          message: t("Error:Error"),
          description:
            err?.message?.length < 100
              ? t(err.message)
              : t("Error:UNKNOWN_ERROR"),
        })
      }
    },
    [context?.wallet?.address, hideModal, requestAuthorization, t]
  )

  return (
    <>
      <Modal width={400} visible={visible} onCancel={hideModal} footer={null}>
        <Space direction="vertical" className="full-width">
          <Typography.Title level={4}>
            {t("Header.SetupOTP.Title")}
          </Typography.Title>
          <Row justify="center">
            <Col>
              <AuthenticatorForm onSuccess={onRegisterOTP} />
            </Col>
          </Row>
        </Space>
      </Modal>
      <Tooltip title={t("Header.SetupOTP.Tooltip")}>
        <Button shape="circle" onClick={showModal} icon={<SettingOutlined />} />
      </Tooltip>
    </>
  )
}

export default SetupOTPWallet

const AuthenticatorForm = ({ onSuccess }) => {
  const { t } = useTranslation("common")
  const [context] = useContext(AppContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [secret, setSecret] = useState()

  const genSecret = useCallback(() => {
    try {
      setSecret()
      if (context?.wallet?.address == null) return
      const secret = authenticator.generateSecret()
      setSecret(secret)
    } catch (err) {
      // console.error(err)
    }
  }, [context?.wallet?.address])

  useEffect(() => {
    genSecret()
  }, [genSecret])

  useEffect(() => {
    form.resetFields()
  }, [form])

  const _onSuccess = useCallback(async () => {
    if (typeof onSuccess === "function") await onSuccess(secret)
  }, [onSuccess, secret])

  const verifyOTP = useCallback(
    async (formData) => {
      try {
        setLoading(true)
        const { otp } = formData
        if (otp !== authenticator.generate(secret))
          throw new Error(t("Error:OTP_VALIDATION_FAILED"))
        await _onSuccess()
      } catch (err) {
        // console.error(err)
        notification.error({
          message: t("Error:Error"),
          description: t("Error:OTP_VALIDATION_FAILED"),
        })
      }
      setLoading(false)
    },
    [_onSuccess, secret, t]
  )

  const otpValidator = useCallback(
    async (_, value) => {
      const reg = /^\d+$/
      if (value?.length !== 6 || !reg.test(value))
        throw new Error(t("Error:InvalidPINCode"))
    },
    [t]
  )

  if (secret == null) return <SpinContainer />

  return (
    <Space direction="vertical">
      <QRCode
        value={authenticator.keyuri(
          context?.wallet?.address,
          "RoundRobin",
          secret
        )}
        size={250}
      />
      <Form form={form} layout="vertical" onFinish={verifyOTP}>
        <Form.Item
          label={t("Header.SetupOTP.ValidationOTP")}
          name="otp"
          rules={[
            {
              required: true,
              validator: otpValidator,
            },
          ]}
        >
          <Input maxLength={6} />
        </Form.Item>
      </Form>
      <Row justify="center">
        <Col>
          <Button
            type="primary"
            className="solid-btn"
            onClick={() => form.submit()}
            loading={loading}
          >
            {t("Header.SetupOTP.Confirm")}
          </Button>
        </Col>
      </Row>
    </Space>
  )
}

AuthenticatorForm.propTypes = {
  secret: PropTypes.string,
  onSuccess: PropTypes.func,
}
