import { ADMIN_API_ROUTE } from "const/api"
import { adminAxios } from "utils/axios"
import i18n from "utils/i18n"

export const requestFeePayer = async (
  rawTransaction,
  methodAbi,
  feePayerAddress,
  options = {}
) => {
  return adminAxios.post(
    ADMIN_API_ROUTE.REQUEST_FEE_PAYER,
    {
      tx: rawTransaction,
      abi: JSON.stringify(methodAbi),
      feePayer: feePayerAddress,
    },
    {
      ...options,
    }
  )
}

export const requestSignFeePayer = async (
  rawTransaction,
  methodAbi,
  feePayerAddress,
  options = {}
) => {
  return adminAxios.post(
    ADMIN_API_ROUTE.REQUEST_SIGN_FEE_PAYER,
    {
      tx: rawTransaction,
      abi: JSON.stringify(methodAbi),
      feePayer: feePayerAddress,
    },
    {
      ...options,
    }
  )
}

export const requestFeePayerReceipt = async (
  rawTransaction,
  methodAbi,
  feePayerAddress,
  options
) => {
  const feeDelegationResponse = await requestFeePayer(
    rawTransaction,
    methodAbi,
    feePayerAddress,
    options
  )
  if (
    feeDelegationResponse.status !== 201 ||
    feeDelegationResponse?.data?.status !== 0
  ) {
    throw new Error(i18n.t("Error:FEE_PAYER_REQUEST_FAIL"))
  }
  if (!feeDelegationResponse?.data?.receipt) {
    throw new Error(i18n.t("Error:MISSING_TRANSACTION_RECEIPT"))
  }
  return feeDelegationResponse.data.receipt
}

export const requestFeePayerReceiptLocal = async (
  caver,
  rawTransaction,
  methodAbi,
  feePayerAddress,
  options
) => {
  const signResponse = await requestSignFeePayer(
    rawTransaction,
    methodAbi,
    feePayerAddress,
    options
  )
  if (signResponse.status !== 201 || signResponse?.data?.status !== 0) {
    throw new Error(i18n.t("Error:FEE_PAYER_REQUEST_FAIL"))
  }
  if (!signResponse?.data?.signedTx) {
    throw new Error(i18n.t("Error:MISSING_TRANSACTION_RECEIPT"))
  }
  try {
    const transaction = await caver.klay.decodeTransaction(
      signResponse.data.signedTx
    )
    return await caver.klay.sendSignedTransaction(transaction)
  } catch (e) {
    console.error(e)
    throw new Error(i18n.t("Error:MISSING_TRANSACTION_RECEIPT"))
  }
}
