import { NFT_MARKET_API_ROUTE } from "const/api"
import pLimit from "p-limit"
import workerInstance from "utils/axios"

export const saleTokenNFT = async (objectData, options = {}) => {
  return workerInstance.post(NFT_MARKET_API_ROUTE.SELL_NFT, objectData, {
    ...options,
  })
}

export const buyTokenNFT = async (objectData, options = {}) => {
  return workerInstance.post(NFT_MARKET_API_ROUTE.BUY_NFT, objectData, {
    ...options,
  })
}

export const getLowestNftToken = async (
  address,
  token_id,
  preferPayToken,
  options = {}
) => {
  return workerInstance.post(
    NFT_MARKET_API_ROUTE.GET_MIN_PRICE_NFT,
    undefined,
    {
      params: {
        address,
        token_id,
        preferPayToken,
      },
      ...options,
    }
  )
}

export const getCollectionDetail = async (address, options = {}) => {
  return workerInstance.get(NFT_MARKET_API_ROUTE.GET_COLLECTION_DETAIL, {
    params: {
      address,
    },
    ...options,
  })
}

export const getCollectionStatistic = async (address, options = {}) => {
  return workerInstance.get(NFT_MARKET_API_ROUTE.GET_COLLECTION_STATISTIC, {
    params: {
      address,
    },
    ...options,
  })
}

export const getCollectionList = async (objData, options = {}) => {
  return workerInstance.post(NFT_MARKET_API_ROUTE.LIST_COLLECTION, objData, {
    ...options,
  })
}

export const getOwnerNfts = async (objData, options = {}) => {
  return workerInstance.post(NFT_MARKET_API_ROUTE.GET_OWNER_NFT, objData, {
    ...options,
  })
}

export const getPreSaleNfts = async (objData, options = {}) => {
  return workerInstance.post(NFT_MARKET_API_ROUTE.LIST_PRESALE_NFT, objData, {
    ...options,
  })
}

export const checkUserCreator = async (address, options = {}) => {
  return workerInstance.get(NFT_MARKET_API_ROUTE.GET_COLLECTION_CREATOR, {
    params: {
      address,
    },
    ...options,
  })
}
export const getHotNFTTokenList = async (params, options = {}) => {
  return workerInstance.get(NFT_MARKET_API_ROUTE.LIST_HOT_NFT, {
    ...options,
    params,
  })
}

export const getNFTOrders = async (objData, options = {}) => {
  return await workerInstance.post(
    NFT_MARKET_API_ROUTE.GET_NFT_ORDER,
    objData,
    {
      ...options,
    }
  )
}

export const getNFTList = async (objData, options = {}) => {
  return await workerInstance.post(NFT_MARKET_API_ROUTE.LIST_NFT, objData, {
    ...options,
  })
}

export const getOwnerNftList = async (objData, options = {}) => {
  return await workerInstance.post(
    NFT_MARKET_API_ROUTE.LIST_NFT_OWNER,
    objData,
    {
      ...options,
    }
  )
}

// Caching Purpose
let payTokenCache = {}
const pLimitPayToken = pLimit(1)
export const getPayTokens = (options) => {
  return pLimitPayToken(_getPayTokens, options)
}
const _getPayTokens = async (options = {}) => {
  if (payTokenCache.data == null) {
    const response = await workerInstance.get(
      NFT_MARKET_API_ROUTE.LIST_PAY_TOKEN,
      {
        ...options,
      }
    )
    const _dataPayTokens = response?.data?.filter((element) => {
      if (element?.useNFTMarket == 1) return element
    })

    payTokenCache = {
      data: _dataPayTokens,
      timestamp: Date.now(),
    }

    return _dataPayTokens
  } else if (Date.now() - payTokenCache.timestamp > 60 * 1000) {
    payTokenCache.data = null
    return _getPayTokens(options)
  } else {
    return payTokenCache.data
  }
}

export const getNftInfo = async (address, tokenId, options = {}) => {
  return await workerInstance.get(NFT_MARKET_API_ROUTE.GET_NFT_DETAIL, {
    params: {
      address,
      tokenId,
    },
    ...options,
  })
}

export const getUserNFTsForSale = async (
  address,
  pageSize,
  pageIndex,
  options = {}
) => {
  return await workerInstance.get(NFT_MARKET_API_ROUTE.GET_USER_NFT_FOR_SALE, {
    params: {
      address,
      pageSize,
      pageIndex,
    },
    ...options,
  })
}

export const postConfirmCancelOrder = async (objData, options = {}) => {
  return await workerInstance.post(
    NFT_MARKET_API_ROUTE.CANCEL_NFT_ORDER,
    objData,
    {
      ...options,
    }
  )
}

export const postEditOrder = async (objData, options = {}) => {
  return await workerInstance.post(
    NFT_MARKET_API_ROUTE.EDIT_NFT_ORDER,
    objData,
    {
      ...options,
    }
  )
}

export const getFilterCategories = async (
  pageIndex = 1,
  pageSize = 20,
  options = {}
) => {
  return await workerInstance.get(NFT_MARKET_API_ROUTE.LIST_FILTER_CATEGORY, {
    params: {
      pageIndex,
      pageSize,
    },
    ...options,
  })
}

export const getHotCollectionList = async (filter = "24h", options = {}) => {
  return await workerInstance.get(NFT_MARKET_API_ROUTE.LIST_HOT_COLLECTION, {
    params: {
      filter,
    },
    ...options,
  })
}
export const getPlatformFee = async (options = {}) => {
  return await workerInstance.get(NFT_MARKET_API_ROUTE.GET_PLATFORM_FEE, {
    ...options,
  })
}

export const getPayOrders = async (
  collectionAddress,
  nftId,
  sellerAddress,
  options = {}
) => {
  return await workerInstance.get(NFT_MARKET_API_ROUTE.GET_PAY_ORDER, {
    params: {
      collectionAddress,
      nftId,
      sellerAddress,
    },
    ...options,
  })
}

export const sellMoreOrder = async (
  collectionAddress,
  tokenId,
  sellerAddress,
  payAddress,
  amount,
  hash,
  options = {}
) => {
  return await workerInstance.post(NFT_MARKET_API_ROUTE.SELL_MORE_ORDER, {
    params: {
      collectionAddress,
      tokenId,
      sellerAddress,
      payAddress,
      amount,
      hash,
    },
    ...options,
  })
}

export const getNFTMarkerCrawlStatus = async () => {
  return await workerInstance.get(NFT_MARKET_API_ROUTE.GET_CRAWLED_BLOCK)
}

export const safeGetNFTMarkerCrawlStatus = async () => {
  try {
    const resp = await getNFTMarkerCrawlStatus()
    return resp?.data?.blockNumber ?? 0
  } catch (err) {
    return 0
  }
}
