import { SMART_CONTRACT_ADDRESS } from "const/smartContracts"
import { TRANSACTION_DEADLINE } from "const/transactions"
import { AppContext } from "index"
import { prepare, getResult } from "klip-sdk"
import { useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { DISPATCH_TYPE } from "services/hooks/Utils/useAppState"
import { getMethodABI } from "utils"
import { findTokenByAddress } from "utils/currency"

const bappName =
  window.CONFIG?.KLIP_APP_NAME || process.env.REACT_APP_KLIP_APP_NAME
const DEFAULT_KLIP_TRANSACTION_TIMEOUT = 10 * 60
const KLIP_TRANSACTION_STATUS_SHOW_TIME = 2000

export const KLIP_REQUEST_STATUS = {
  PREPARED: "prepared",
  REQUESTED: "requested",
  COMPLETED: "completed",
  ERROR: "error",
  EXPIRED: "expired",
}

export const useKlipWalletProvider = () => {
  const [, dispatch] = useContext(AppContext)
  const { t } = useTranslation("Error")

  const showKlipTransactionModal = useCallback(
    (requestKey, expirationTime, onCancelTransaction) => {
      dispatch({
        type: DISPATCH_TYPE.INIT_KLIP_TRANSACTION,
        payload: {
          requestKey,
          expirationTime,
          onCancelTransaction,
          status: KLIP_REQUEST_STATUS.REQUESTED,
        },
      })
    },
    [dispatch]
  )

  const hideCurrentKlipTransactionModal = useCallback(() => {
    dispatch({
      type: DISPATCH_TYPE.CANCEL_KLIP_TRANSACTION,
    })
  }, [dispatch])

  const executeContract = useCallback(
    async (
      smartContractAddress,
      methodName,
      { from, value, params, successLink, failLink }
    ) => {
      return new Promise((_resolve, _reject) => {
        const resolve = (data) => {
          dispatch({
            type: DISPATCH_TYPE.UPDATE_KLIP_TRANSACTION,
            payload: {
              status: KLIP_REQUEST_STATUS.COMPLETED,
            },
          })
          setTimeout(() => {
            setTimeout(
              hideCurrentKlipTransactionModal,
              KLIP_TRANSACTION_STATUS_SHOW_TIME
            )
            _resolve(data)
          }, KLIP_TRANSACTION_STATUS_SHOW_TIME)
        }

        const reject = (error) => {
          dispatch({
            type: DISPATCH_TYPE.UPDATE_KLIP_TRANSACTION,
            payload: {
              status: KLIP_REQUEST_STATUS.ERROR,
            },
          })
          setTimeout(() => {
            _reject(error)
          }, KLIP_TRANSACTION_STATUS_SHOW_TIME)
        }

        try {
          const methodABI = getMethodABI(smartContractAddress, methodName)
          prepare
            .executeContract({
              bappName,
              from,
              to: smartContractAddress,
              value: normalizeValue(value) || "0",
              abi:
                typeof methodABI === "string"
                  ? methodABI
                  : JSON.stringify(methodABI),
              params: JSON.stringify(params || []),
              successLink,
              failLink,
            })
            .then(
              async (res) => {
                if (res.err) {
                  reject(new Error(res.err))
                } else if (res.request_key) {
                  // Getting first result to check if valid request key
                  const requestKey = res.request_key
                  const response = await getResult(requestKey)
                  const expirationTime =
                    parseInt(
                      response.expiration_time -
                        DEFAULT_KLIP_TRANSACTION_TIMEOUT
                    ) +
                    TRANSACTION_DEADLINE / 1000
                  const loop = setInterval(async () => {
                    const response = await getResult(requestKey)
                    const _expirationTime =
                      parseInt(
                        response.expiration_time -
                          DEFAULT_KLIP_TRANSACTION_TIMEOUT
                      ) +
                      TRANSACTION_DEADLINE / 1000
                    if (Date.now() > _expirationTime * 1000) {
                      clearInterval(loop)
                      reject(new Error(t("Error:KLIP_TRANSACTION_EXPIRED")))
                    } else {
                      switch (response.status) {
                        case KLIP_REQUEST_STATUS.COMPLETED:
                          clearInterval(loop)
                          if (response.result.status === "fail")
                            reject(
                              new Error(t("Error:KLIP_TRANSACTION_FAILED"))
                            )
                          else resolve(response.result)
                          break
                        case KLIP_REQUEST_STATUS.ERROR:
                          clearInterval(loop)
                          reject(new Error(response.error?.message))
                          break
                        default:
                          return
                      }
                    }
                  }, 1000)
                  showKlipTransactionModal(requestKey, expirationTime, () => {
                    clearInterval(loop)
                  })
                } else {
                  reject(new Error("NO_REQUEST_KEY_RETURNED"))
                }
              },
              (err) => {
                reject(err)
              }
            )
        } catch (err) {
          reject(err)
        }
      })
    },
    [dispatch, hideCurrentKlipTransactionModal, showKlipTransactionModal, t]
  )

  const executeContractWithMethodABI = useCallback(
    async (
      smartContractAddress,
      methodABI,
      { from, value, params, successLink, failLink }
    ) => {
      return new Promise((_resolve, _reject) => {
        const resolve = (data) => {
          dispatch({
            type: DISPATCH_TYPE.UPDATE_KLIP_TRANSACTION,
            payload: {
              status: KLIP_REQUEST_STATUS.COMPLETED,
            },
          })
          setTimeout(() => {
            setTimeout(
              hideCurrentKlipTransactionModal,
              KLIP_TRANSACTION_STATUS_SHOW_TIME
            )
            _resolve(data)
          }, KLIP_TRANSACTION_STATUS_SHOW_TIME)
        }

        const reject = (error) => {
          dispatch({
            type: DISPATCH_TYPE.UPDATE_KLIP_TRANSACTION,
            payload: {
              status: KLIP_REQUEST_STATUS.ERROR,
            },
          })
          setTimeout(() => {
            _reject(error)
          }, KLIP_TRANSACTION_STATUS_SHOW_TIME)
        }

        try {
          prepare
            .executeContract({
              bappName,
              from,
              to: smartContractAddress,
              value: normalizeValue(value) || "0",
              abi:
                typeof methodABI === "string"
                  ? methodABI
                  : JSON.stringify(methodABI),
              params: JSON.stringify(params || []),
              successLink,
              failLink,
            })
            .then(
              async (res) => {
                if (res.err) {
                  reject(new Error(res.err))
                } else if (res.request_key) {
                  // Getting first result to check if valid request key
                  const requestKey = res.request_key
                  const response = await getResult(requestKey)
                  const expirationTime =
                    parseInt(
                      response.expiration_time -
                        DEFAULT_KLIP_TRANSACTION_TIMEOUT
                    ) +
                    TRANSACTION_DEADLINE / 1000
                  const loop = setInterval(async () => {
                    const response = await getResult(requestKey)
                    const _expirationTime =
                      parseInt(
                        response.expiration_time -
                          DEFAULT_KLIP_TRANSACTION_TIMEOUT
                      ) +
                      TRANSACTION_DEADLINE / 1000
                    if (Date.now() > _expirationTime * 1000) {
                      clearInterval(loop)
                      reject(new Error(t("Error:KLIP_TRANSACTION_EXPIRED")))
                    } else {
                      switch (response.status) {
                        case KLIP_REQUEST_STATUS.COMPLETED:
                          clearInterval(loop)
                          if (response.result.status === "fail")
                            reject(
                              new Error(t("Error:KLIP_TRANSACTION_FAILED"))
                            )
                          else resolve(response.result)
                          break
                        case KLIP_REQUEST_STATUS.ERROR:
                          clearInterval(loop)
                          reject(new Error(response.error?.message))
                          break
                        default:
                          return
                      }
                    }
                  }, 1000)
                  showKlipTransactionModal(requestKey, expirationTime, () => {
                    clearInterval(loop)
                  })
                } else {
                  reject(new Error("NO_REQUEST_KEY_RETURNED"))
                }
              },
              (err) => {
                reject(err)
              }
            )
        } catch (err) {
          reject(err)
        }
      })
    },
    [dispatch, hideCurrentKlipTransactionModal, showKlipTransactionModal, t]
  )

  const sendKLAY = useCallback(
    (destination, amount, { from, successLink, failLink }) => {
      return new Promise((_resolve, _reject) => {
        const resolve = (data) => {
          dispatch({
            type: DISPATCH_TYPE.UPDATE_KLIP_TRANSACTION,
            payload: {
              status: KLIP_REQUEST_STATUS.COMPLETED,
            },
          })
          setTimeout(() => {
            setTimeout(
              hideCurrentKlipTransactionModal,
              KLIP_TRANSACTION_STATUS_SHOW_TIME
            )
            _resolve(data)
          }, KLIP_TRANSACTION_STATUS_SHOW_TIME)
        }

        const reject = (error) => {
          dispatch({
            type: DISPATCH_TYPE.UPDATE_KLIP_TRANSACTION,
            payload: {
              status: KLIP_REQUEST_STATUS.ERROR,
            },
          })
          setTimeout(() => {
            _reject(error)
          }, KLIP_TRANSACTION_STATUS_SHOW_TIME)
        }

        try {
          prepare
            .sendKLAY({
              bappName,
              from,
              to: destination,
              amount: normalizeAmount(amount),
              successLink,
              failLink,
            })
            .then(
              async (res) => {
                if (res.err) {
                  reject(new Error(res.err))
                } else if (res.request_key) {
                  // Getting first result to check if valid request key
                  const requestKey = res.request_key
                  const response = await getResult(requestKey)
                  const expirationTime =
                    parseInt(
                      response.expiration_time -
                        DEFAULT_KLIP_TRANSACTION_TIMEOUT
                    ) +
                    TRANSACTION_DEADLINE / 1000
                  const loop = setInterval(async () => {
                    const response = await getResult(requestKey)
                    const _expirationTime =
                      parseInt(
                        response.expiration_time -
                          DEFAULT_KLIP_TRANSACTION_TIMEOUT
                      ) +
                      TRANSACTION_DEADLINE / 1000
                    if (Date.now() > _expirationTime * 1000) {
                      clearInterval(loop)
                      reject(new Error(t("Error:KLIP_TRANSACTION_EXPIRED")))
                    } else {
                      switch (response.status) {
                        case KLIP_REQUEST_STATUS.COMPLETED:
                          clearInterval(loop)
                          if (response.result.status === "fail")
                            reject(
                              new Error(t("Error:KLIP_TRANSACTION_FAILED"))
                            )
                          else resolve(response.result)
                          break
                        case KLIP_REQUEST_STATUS.ERROR:
                          clearInterval(loop)
                          reject(new Error(response.error?.message))
                          break
                        default:
                          return
                      }
                    }
                  }, 1000)
                  showKlipTransactionModal(requestKey, expirationTime, () => {
                    clearInterval(loop)
                  })
                } else {
                  reject(new Error("NO_REQUEST_KEY_RETURNED"))
                }
              },
              (err) => {
                reject(err)
              }
            )
        } catch (err) {
          reject(err)
        }
      })
    },
    [dispatch, hideCurrentKlipTransactionModal, showKlipTransactionModal, t]
  )

  return {
    executeContract,
    executeContractWithMethodABI,
    hideCurrentKlipTransactionModal,
    sendKLAY,
  }
}

const RRB_WKLAY_DECIMALS =
  findTokenByAddress(SMART_CONTRACT_ADDRESS.WKLAY)?.decimals || 18

const KLIP_WKLAY_DECIMALS = 6

const DECIMALS_DIFF = KLIP_WKLAY_DECIMALS - RRB_WKLAY_DECIMALS

const normalizeValue = (value) => {
  if (!value) return 0
  return `${value.slice(0, DECIMALS_DIFF)}${Array(
    Math.abs(DECIMALS_DIFF) + 1
  ).join("0")}`
}

const normalizeAmount = (value) => {
  if (!value) return 0

  const [integer, decimals] = value.split(".")

  if (!decimals) return integer

  return `${integer}.${decimals.slice(0, KLIP_WKLAY_DECIMALS)}`
}
