import { useReducer } from "react"
import { logoutWallet } from "services/wallet-providers"
import { notification } from "antd"
import i18n from "utils/i18n"

export const DISPATCH_TYPE = {
  CONNECT_WALLET: "CONNECT_WALLET",
  CHANGE_WALLET: "CHANGE_WALLET",
  KLIP_WALLET_EXPIRED: "KLIP_WALLET_EXPIRED",
  INIT_CAVER: "INIT_CAVER",
  INIT_KLAYTN_CAVER: "INIT_KLAYTN_CAVER",
  INIT_KLIP_TRANSACTION: "INIT_KLIP_TRANSACTION",
  UPDATE_KLIP_TRANSACTION: "UPDATE_KLIP_TRANSACTION",
  CANCEL_KLIP_TRANSACTION: "CANCEL_KLIP_TRANSACTION",
  PENDING_ACTION: "PENDING_ACTION",
  CONNECTION_STATUS: "CONNECTION_STATUS",
  UPDATE_NFT_MARKET_PAYTOKEN: "UPDATE_NFT_MARKET_PAYTOKEN",
}

const defaultAppState = {
  wallet: undefined,
  isOnline: true,
  pendingAction: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case DISPATCH_TYPE.KLIP_WALLET_EXPIRED:
    case DISPATCH_TYPE.CHANGE_WALLET:
      logoutWallet()
      return {
        ...state,
        ...defaultAppState,
      }
    case DISPATCH_TYPE.CONNECT_WALLET:
      if (action.payload && !state.wallet) {
        notification.success({
          message: i18n.t("common:ConnectWallet.Notification.Success.Title"),
          description: i18n.t(
            "common:ConnectWallet.Notification.Success.Description",
            {
              provider: action.payload.provider,
            }
          ),
        })
      }
      return {
        ...state,
        wallet: action.payload,
      }
    case DISPATCH_TYPE.INIT_KLIP_TRANSACTION:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          lastTransaction: Date.now(),
        },
        klipCurrentTransaction: action.payload,
      }
    case DISPATCH_TYPE.UPDATE_KLIP_TRANSACTION:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          lastTransaction: Date.now(),
        },
        klipCurrentTransaction: {
          ...state.klipCurrentTransaction,
          ...action.payload,
        },
      }
    case DISPATCH_TYPE.CANCEL_KLIP_TRANSACTION:
      // Modal.destroyAll()
      return {
        ...state,
        klipCurrentTransaction: undefined,
      }
    case DISPATCH_TYPE.PENDING_ACTION:
      return {
        ...state,
        pendingAction: {
          ...state.pendingAction,
          ...action.payload,
        },
      }
    case DISPATCH_TYPE.CONNECTION_STATUS:
      return {
        ...state,
        isOnline: action.payload,
      }
    case DISPATCH_TYPE.UPDATE_REWARD_TOKEN_STATUS:
      return {
        ...state,
        rewardTokenInfo: action.payload,
      }
    case DISPATCH_TYPE.PREVENT_REWARD_TOKEN_UPDATE:
      return {
        ...state,
        rewardTokenInfo: {
          ...state?.rewardTokenInfo,
          lastUpdate: Date.now(),
        },
      }
    case DISPATCH_TYPE.UPDATE_NFT_MARKET_PAYTOKEN:
      return {
        ...state,
        nftMarket: {
          ...state?.nftMarket,
          payTokens: action.payload,
        },
      }
    default:
      return state
  }
}

const useAppState = () => useReducer(reducer, defaultAppState)

export default useAppState
