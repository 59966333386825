/* eslint-disable no-fallthrough */
import { useCallback, useEffect, useRef, useState } from "react"
import { getResult } from "klip-sdk"
import { useTranslation } from "react-i18next"

export const KLIP_REQUEST_STATUS = {
  PREPARED: "prepared",
  REQUESTED: "requested",
  COMPLETED: "completed",
  ERROR: "error",
  EXPIRED: "expired",
}

const KLIP_TIMEOUT = 10 * 60

const useKlipResultChecker = (expired = 5 * 60) => {
  const _expired = Math.min(expired, KLIP_TIMEOUT)
  const [reqKey, setReqKey] = useState()
  const loop = useRef()
  const [result, setResult] = useState()
  const [status, setStatus] = useState()
  const [expirationTime, setExpirationTime] = useState()
  const { t } = useTranslation("Error")

  const _setReqKey = useCallback((value) => {
    setResult()
    setStatus()
    setExpirationTime()
    setReqKey(value)
  }, [])

  const fetchResult = useCallback(async () => {
    if (!reqKey) return
    try {
      const response = await getResult(reqKey)
      const _expirationTime =
        parseInt(response.expiration_time - KLIP_TIMEOUT) + _expired
      setExpirationTime(_expirationTime)
      if (Date.now() > _expirationTime * 1000)
        return setStatus(KLIP_REQUEST_STATUS.EXPIRED)
      setResult(response.result)
      setStatus(response.status)
    } catch (err) {
      setResult()
    }
  }, [_expired, reqKey])

  useEffect(() => {
    fetchResult()
    loop.current = setInterval(fetchResult, 2000)
    return () => {
      clearInterval(loop.current)
    }
  }, [fetchResult])

  useEffect(() => {
    switch (status) {
      case KLIP_REQUEST_STATUS.EXPIRED:
        setResult({
          err: new Error(t("Error:REQUEST_EXPIRED")),
        })
      case KLIP_REQUEST_STATUS.ERROR:
      case KLIP_REQUEST_STATUS.COMPLETED:
        clearInterval(loop.current)
        break
      default:
    }
  }, [status, t])

  return {
    setReqKey: _setReqKey,
    result,
    status,
    expirationTime,
  }
}

export default useKlipResultChecker
