import {
  convertToPeb,
  convertFromPeb,
  klayUnit,
  isBigNumber,
  toBN,
} from "caver-js/packages/caver-utils"
import { ALT_TOKEN, TOKEN_TYPES } from "const/token"
import { BigInt } from "jsbi"
import { removeDecimal } from "utils"
import {
  isSameAddress,
  numberWithCommas,
  reduceDecimalToMatch,
  removeCommas,
} from "./number"

export const currencyAmount = (amount, token) => {
  const decimals = token.decimals
  const _amount = reduceDecimalToMatch(amount, decimals)
  return BigInt(toPeb(_amount, decimals).toString())
}

export const humanReadableAmount = (amount, token) => {
  const decimals = token.decimals
  if (amount == null || amount.length === 0) return
  if (typeof amount === "string")
    return fromPeb(removeDecimal(amount), decimals)
  return fromPeb(amount, decimals)
}

export const findTokenByAddress = (address) => {
  if (!address) return
  return [...TOKEN_TYPES, ...Object.values(ALT_TOKEN)].find((ele) =>
    isSameAddress(ele.address, address)
  )
}

const PEB_DECIMALS = 18
const TEN_D = toBN("10")

const KLAY_UNITS = Object.values(klayUnit).reduce((units, ele) => {
  units[`${ele.pebFactor}`] = ele.unit
  return units
}, {})

const toPeb = (amount, decimals) => {
  decimals = +decimals
  const unit = KLAY_UNITS[`${decimals}`]
  if (!isBigNumber(amount)) {
    amount = `${amount}`
  }
  if (unit) {
    return toBN(convertToPeb(amount, unit))
  }
  const exp = TEN_D.pow(toBN(Math.abs(PEB_DECIMALS - decimals)))
  if (decimals > PEB_DECIMALS) {
    if (isBigNumber(amount)) {
      if (isBigNumber(amount))
        amount = toBN(
          amount.toFormat({
            decimalSeparator: undefined,
          })
        )
      else {
        amount = toBN(amount.toString())
      }
      return amount.mul(exp)
    }
    const parts = amount.split(".")
    const integer = parts[0]
    let fraction = parts[1]
    if (!fraction) {
      return toBN(amount).mul(exp)
    }
    let plus = toBN(0)
    if (fraction.length > decimals) {
      plus = toBN(fraction.substring(PEB_DECIMALS))
      fraction = fraction.substring(0, PEB_DECIMALS)
    }
    const peb = toBN(convertToPeb(`${integer}.${fraction}`))
    return peb.mul(exp).add(plus)
  } else {
    const bAmount = toBN(convertToPeb(amount))
    return bAmount.div(exp)
  }
}

const fromPeb = (amount, decimals) => {
  if (isBigNumber(amount))
    amount = toBN(
      amount.toFormat({
        decimalSeparator: undefined,
      })
    )
  else {
    amount = toBN(amount.toString())
  }
  const unit = KLAY_UNITS[`${decimals}`]
  if (unit) {
    return convertFromPeb(amount, unit)
  }
  const exp = TEN_D.pow(toBN(decimals))
  const integer = amount.div(exp)
  let fraction = amount.sub(integer.mul(exp)).toString()
  while (fraction.length < decimals) {
    fraction = `0${fraction}`
  }
  while (fraction.endsWith("0")) {
    fraction = fraction.substring(0, fraction.length - 1)
  }
  return `${integer.toString()}.${fraction}`
}

export const genExponent = (dec) => {
  return BigInt(`1${new Array(Math.abs(dec) + 1).join("0")}`)
}

export const inputFormatter = (value) => {
  if (!value) return value
  const _value = value.replace(/[^\d.]*/g, "")
  if (!_value) return value
  const [integer, decimals] = _value.split(".")
  if (decimals)
    return numberWithCommas([integer, decimals.slice(0, 6)].join("."))
  return numberWithCommas(_value)
}

export const inputParser = (value) => {
  return removeCommas(value)
}
