import { BigNumber } from "ethers"
import BNJS from "bignumber.js"
import { removeDecimal } from "utils"
import Web3 from "web3"

BNJS.config({
  FORMAT: {
    prefix: "",
    decimalSeparator: ".",
    groupSeparator: "",
    groupSize: 0,
    secondaryGroupSize: 0,
    fractionGroupSeparator: "",
    fractionGroupSize: 0,
    suffix: "",
  },
})

export const STAKING_PENALTY_FEE_DECIMALS = 2 + 3 // 3 for decimals and 2 for percentage

export function padLeadingZeros(num, size) {
  var s = parseInt(num) + ""
  while (s.length < size) s = "0" + s
  return s
}

export function numberWithCommas(x) {
  try {
    const parts = x.toString().split(".")
    return parts
      .map((part, i) =>
        i === 0 ? part.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : part
      )
      .join(".")
  } catch (err) {
    return NaN
  }
}

export function removeCommas(x) {
  try {
    return x.replace(/(,*)/g, "")
  } catch (err) {
    return NaN
  }
}

export function countDecimal(number) {
  if (!number) return 0
  const str = number.toString()
  if (str.indexOf(".") === -1) return 0
  return str.split(".")[1]?.length || 0
}

export function reduceDecimalToMatch(value, expectDecimals) {
  if (value == null || value?.length === 0) return "0"

  if (isNaN(expectDecimals)) return value

  const [integer, decimals] = `${value}`.split(".")

  if (expectDecimals === 0) return integer

  if (!decimals) return integer

  return `${integer}.${decimals.slice(0, expectDecimals)}`
}

export const calBNRate = (a, b) => {
  try {
    if (a == null || b == null || b === "0" || b === 0) return
    const _a = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${a}`, 18))
    )
    const _b = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${b}`, 18))
    )

    if (_b.eq(BigNumber.from("0"))) return

    return Web3.utils.fromWei(
      _a
        .mul(BigNumber.from(Web3.utils.toWei("100")))
        .div(_b)
        .toString()
    )
  } catch (err) {
    console.error(err)
    return
  }
}

export const mulBN = (a, b) => {
  try {
    if (a == null || b == null) return
    const _a = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${a}`, 18))
    )
    const _b = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${b}`, 18))
    )

    return Web3.utils.fromWei(
      removeDecimal(Web3.utils.fromWei(_a.mul(_b).toString()))
    )
  } catch (err) {
    console.error(err)
    return
  }
}

export const mulBNs = (...args) => {
  return args.reduce((cum, cur) => mulBN(cum, cur), "1")
}

export const divBN = (a, b) => {
  try {
    if (a == null || b == null || b === "0" || b === 0) return
    const _a = BigNumber.from(
      Web3.utils.toWei(Web3.utils.toWei(reduceDecimalToMatch(`${a}`, 18)))
    )
    const _b = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${b}`, 18))
    )

    if (_b.eq(BigNumber.from("0"))) return

    return Web3.utils.fromWei(_a.div(_b).toString())
  } catch (err) {
    console.error(err)
    return
  }
}

export const sumBN = (a, b) => {
  try {
    if (a == null || b == null) return
    const _a = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${a}`, 18))
    )
    const _b = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${b}`, 18))
    )

    return Web3.utils.fromWei(_a.add(_b).toString())
  } catch (err) {
    console.error(err)
    return
  }
}

export const sumBNs = (...args) => {
  return args.reduce((cum, cur) => sumBN(cum, cur), "0")
}

export const subBN = (a, b) => {
  try {
    if (a == null || b == null) return
    const _a = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${a}`, 18))
    )
    const _b = BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${b}`, 18))
    )

    return Web3.utils.fromWei(_a.sub(_b).toString())
  } catch (err) {
    console.error(err)
    return
  }
}

export const avgBN = (...args) => {
  try {
    if (args.length === 0) return 0
    return divBN(
      args.reduce((cum, cur) => sumBN(cum, cur), "0"),
      args.length
    )
  } catch (err) {
    console.error(err)
    return
  }
}

export const compareBN = (a, b) => {
  try {
    return new BNJS(a ?? 0).gte(new BNJS(b ?? 0)) ? 1 : -1
  } catch (err) {
    console.log(err)
    return 0
  }
}

export const removeInsignificantDecimals = (value) => {
  return `${value}`?.replace?.(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1")
}

export const isBNEqualsZero = (value) => {
  try {
    if (isNaN(value)) return true
    return BigNumber.from(
      Web3.utils.toWei(reduceDecimalToMatch(`${value}`, 18)).toString()
    ).eq(BigNumber.from("0"))
  } catch (err) {
    return true
  }
}

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000"

export const isZeroAddress = (address) => {
  try {
    if (address == null) return true
    if (!Web3.utils.isAddress(address)) return true
    return address === ZERO_ADDRESS
  } catch (err) {
    return true
  }
}

export const isSameAddress = (a, b) => {
  try {
    if (a == null || b == null) return false
    return Web3.utils.toChecksumAddress(a) === Web3.utils.toChecksumAddress(b)
  } catch (err) {
    return false
  }
}

export const numberOrDefault = (num, defaultReturn = 0) => {
  if (isNaN(num)) return defaultReturn
  return num
}
