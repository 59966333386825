import Axios from "axios"
// import { notification } from "antd"
// import i18n from "./i18n"

const workerInstance = Axios.create({
  baseURL:
    window.CONFIG?.WORKER_API_ENDPOINT ||
    process.env.REACT_APP_WORKER_API_ENDPOINT,
})

const workerInstanceOld = Axios.create({
  baseURL:
    window.CONFIG?.WORKER_API_ENDPOINT_OLD ||
    process.env.REACT_APP_WORKER_API_ENDPOINT_OLD,
})

const adminInstance = Axios.create({
  baseURL:
    window.CONFIG?.ADMIN_API_ENDPOINT ||
    process.env.REACT_APP_ADMIN_API_ENDPOINT,
})

const dataAdminInstance = Axios.create({
  baseURL:
    window.CONFIG?.DATA_ADMIN_API_ENDPOINT ||
    process.env.REACT_APP_DATA_ADMIN_API_ENDPOINT,
})

const nftInstance = Axios.create({
  baseURL:
    window.CONFIG?.NFT_API_ENDPOINT || process.env.REACT_APP_NFT_API_ENDPOINT,
})

const request = async (axiosFunc, ...args) => {
  try {
    return await axiosFunc(...args)
  } catch (error) {
    if (error.message === "Network Error") return error.response
    // notification.error({
    //   message: i18n.t("APIMessage:Error"),
    //   description: i18n.t(`APIMessage:${error.response?.data?.message || error.message || "Server Error"}`)
    // })
    return error.response
  }
}

/**
 * @param {string} path
 * @param  {import("axios").AxiosRequestConfig} configs
 * @returns {import("axios").AxiosResponse}
 */
// prettier-ignore
const _get =
  (instance) =>
    (path, configs = {}) =>
      request(instance.get, path, configs)

/**
 * @param {string} path
 * @param {any} data
 * @param  {import("axios").AxiosRequestConfig} configs
 * @returns {import("axios").AxiosResponse}
 */
// prettier-ignore
const _post =
  (instance) =>
    (path, data = {}, configs = {}) =>
      request(instance.post, path, data, configs)

/**
 * @param {string} path
 * @param {any} data
 * @param  {import("axios").AxiosRequestConfig} configs
 * @returns {import("axios").AxiosResponse}
 */
// prettier-ignore
const _patch =
  (instance) =>
    (path, data = {}, configs = {}) =>
      request(instance.patch, path, data, configs)

/**
 * @param {string} path
 * @param  {import("axios").AxiosRequestConfig} configs
 * @returns {import("axios").AxiosResponse}
 */
// prettier-ignore
const _delete =
  (instance) =>
    (path, configs = {}) =>
      request(instance.delete, path, configs)

const axios = {
  get: _get(workerInstance),
  post: _post(workerInstance),
  patch: _patch(workerInstance),
  delete: _delete(workerInstance),
  instance: workerInstance,
}

export default axios

export const axiosOld = {
  get: _get(workerInstanceOld),
  post: _post(workerInstanceOld),
  patch: _patch(workerInstanceOld),
  delete: _delete(workerInstanceOld),
  instance: workerInstanceOld,
}

export const adminAxios = {
  get: _get(adminInstance),
  post: _post(adminInstance),
  patch: _patch(adminInstance),
  delete: _delete(adminInstance),
  instance: adminInstance,
}

export const nftAxios = {
  get: _get(nftInstance),
  post: _post(nftInstance),
  patch: _patch(nftInstance),
  delete: _delete(nftInstance),
  instance: nftInstance,
}

export const dataAdminAxios = {
  get: _get(dataAdminInstance),
  post: _post(dataAdminInstance),
  patch: _patch(dataAdminInstance),
  delete: _delete(dataAdminInstance),
  instance: dataAdminInstance,
}
