import { WORKER_API_ROUTE } from "const/api"
import pLimit from "p-limit"
import { clearAuthorization } from "utils/auth"
import axios from "utils/axios"
import { humanReadableAmount } from "utils/currency"
import { mulBN } from "utils/number"
import Web3 from "web3"
import hash from "object-hash"

const QUERY_LIMIT = 100

export const enqueueUnstake = async (stakeId, token, options = {}) => {
  const response = await axios.post(
    `${WORKER_API_ROUTE.ENQUEUE_UNSTAKE}/${stakeId}`,
    undefined,
    {
      ...options,
      headers: {
        "x-token": token,
      },
    }
  )
  if (response.status === 401) clearAuthorization()
  return response
}

// Caching purpose
let rewardConfig
const pLimitRewardConfig = pLimit(1)
export const getRewardConfig = async (options = {}) => {
  return pLimitRewardConfig(_getRewardConfig, options)
}
const _getRewardConfig = async (options) => {
  if (rewardConfig == null) {
    const response = await axios.get(WORKER_API_ROUTE.REWARD_CONFIG, {
      ...options,
    })
    if (response.status === 200) {
      rewardConfig = {
        timestamp: Date.now(),
        data: response,
      }
    }
    return response
  } else if (Date.now() - rewardConfig.timestamp > 60 * 1000) {
    rewardConfig = null
    return await _getRewardConfig(options)
  } else {
    return rewardConfig.data
  }
}

// Caching purpose
const tsRewards = {}
const pLimitTSReward = pLimit(1)
export const getRewardFarming = async (stakingPoolAddress, options) => {
  return pLimitTSReward(_getRewardFarming, stakingPoolAddress, options)
}
const _getRewardFarming = async (stakingPoolAddress, options) => {
  if (tsRewards[stakingPoolAddress] == null) {
    const response = await axios.get(WORKER_API_ROUTE.FARMING_CONFIG, {
      params: {
        assignee: stakingPoolAddress,
        enabled: true,
      },
      ...options,
    })
    if (response.status === 200) {
      tsRewards[stakingPoolAddress] = {
        timestamp: Date.now(),
        data: response,
      }
    }
    return response
  } else if (Date.now() - tsRewards[stakingPoolAddress].timestamp > 60 * 1000) {
    tsRewards[stakingPoolAddress] = null
    return await _getRewardFarming(stakingPoolAddress, options)
  } else {
    return tsRewards[stakingPoolAddress].data
  }
}

export const getStakingRewardAmount = async (stakingPoolAddress, options) => {
  const result = []
  // const rewardConfig = (await getRewardConfig(options))?.data
  // if (stakingPoolAddress === SMART_CONTRACT_ADDRESS.VS_POOL && rewardConfig != null)
  //   result.push(
  //     {
  //       address: REWARD_TOKEN?.address,
  //       daily: rewardConfig?.vsPool?.rewardPerDay || "0",
  //     }
  //   )
  const farmingConfigs = (await getRewardFarming(stakingPoolAddress, options))
    ?.data
  if (farmingConfigs != null)
    result.push(
      ...farmingConfigs?.map((reward) => ({
        ...reward,
        address: Web3.utils.toChecksumAddress(reward.token),
        farmAddress: reward.address,
        daily: mulBN(
          humanReadableAmount(reward.rewardPerBlock, {
            decimals: reward.tokenDecimals,
          }),
          24 * 60 * 60
        ),
      }))
    )
  return result
}

export const saveStakingRewardSnapshot = (stakeId, rewards) => {
  return axios.patch(
    WORKER_API_ROUTE.UPDATE_STAKING_REWARD_SNAPSHOT.replace(":id", stakeId),
    {
      rewards,
    }
  )
}

export const safeGetStakingTokens = async (stakingPoolAddress) => {
  try {
    const response = await fetchStakingPoolTokens(stakingPoolAddress)
    if (typeof response?.data?.map !== "function")
      throw new Error("Invalid data")
    return response.data
  } catch (err) {
    return []
  }
}

export const fetchStakingPool = async (stakingPoolAddress, options) => {
  return axios.get(WORKER_API_ROUTE.STAKING_POOL, {
    params: {
      poolAddress: stakingPoolAddress,
    },
    ...options,
  })
}

// Caching purpose
let stakingPoolTokens = {}
const pLimitStakingPoolTokens = pLimit(1)
export const fetchStakingPoolTokens = async (
  stakingPoolAddress,
  enabled,
  options
) => {
  return pLimitStakingPoolTokens(
    _fetchStakingPoolTokens,
    stakingPoolAddress,
    enabled,
    options
  )
}
const _fetchStakingPoolTokens = async (
  stakingPoolAddress,
  enabled = true,
  options
) => {
  // if(stakingPoolAddress === SMART_CONTRACT_ADDRESS.VS_POOL) return {
  //   data: [
  //     REWARD_TOKEN,
  //     TOKEN_TYPES_OBJECT["DUCATO"]
  //   ]
  // }
  const _stakingPoolAddress = Web3.utils.toChecksumAddress(stakingPoolAddress)
  if (!_stakingPoolAddress) return
  const requestHash = hash({
    stakingPoolAddress,
    options,
  })
  if (stakingPoolTokens[requestHash] == null) {
    const response = await axios.get(WORKER_API_ROUTE.STAKING_POOL_TOKEN, {
      params: {
        poolAddress: stakingPoolAddress,
        enabled: enabled,
      },
      ...options,
    })
    if (response.status === 200) {
      stakingPoolTokens[requestHash] = {
        timestamp: Date.now(),
        data: response,
      }
    }
    return response
  } else if (
    Date.now() - stakingPoolTokens[requestHash].timestamp >
    60 * 1000
  ) {
    stakingPoolTokens[requestHash] = null
    return await _fetchStakingPoolTokens(_stakingPoolAddress, options)
  } else {
    return stakingPoolTokens[requestHash].data
  }
}

export const fetchStakingHistory = async (
  stakingPoolAddress,
  userAddress,
  options
) => {
  return axios.get(WORKER_API_ROUTE.STAKING_HISTORY, {
    params: {
      poolAddress: stakingPoolAddress,
      userAddress: userAddress,
      perPage: QUERY_LIMIT,
    },
    ...options,
  })
}
