export const ENDPOINT_NODE_STATUS = {
  NORMAL: "normal",
  SLOW_SYNC: "slow_sync",
  DOWN: "down",
}

export const TRANSACTION_STATUS = {
  SUCCESS: "success",
  PROCESSING: "processing",
  FAIL: "fail",
}

export const SERVICE_STOP = window.CONFIG?.SERVICE_STOP ?? false
