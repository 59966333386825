import { convertArrayToObject } from "utils"
import { CHAIN, SMART_CONTRACT_ADDRESS } from "./smartContracts"

export const TOKEN_TYPES = window.TOKENS ?? [
  {
    name: "Ducato Protocol Token",
    chainId: 1001,
    symbol: "DUCATO",
    decimals: 18,
    address: "0xca69042D71dc19c1D1ec10fA3Cbb4aaE3E469391",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/7133.png",
  },
  {
    name: "Round Robin Token",
    chainId: 1001,
    symbol: "RRT",
    decimals: 18,
    address: "0x5f0598ec27036d9a7Cd0bB04e3d36b2021466477",
    logoURI:
      "https://assets.materialup.com/uploads/30080ad4-97ee-47f7-a98a-c0d2e8d704b2/preview",
  },
  {
    name: "WBitcoin",
    chainId: 1001,
    symbol: "WBTC",
    decimals: 18,
    address: "0xC893a49eDfe77e2ccF56cD8545358ED818033086",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png",
  },
  {
    name: "WEther",
    chainId: 1001,
    symbol: "WETH",
    decimals: 18,
    address: "0xdC00b1Cee9467d5eeE9267DD8eF205A8aA3D550e",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
  },
  {
    name: "Wrapped KLAY",
    chainId: 1001,
    symbol: "WKLAY",
    decimals: 18,
    address: "0x034CA55009C686Ef75E84676403905c307c21695",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4256.png",
  },
  {
    name: "WUSDT",
    chainId: 1001,
    symbol: "WUSDT",
    decimals: 18,
    address: "0x6F7C31D190662cE5e4330d721ffef828AA525DFe",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
  },
]

export const TOKEN_TYPES_OBJECT = convertArrayToObject(TOKEN_TYPES, "symbol")

export const CHAIN_ID = window.CONFIG?.CHAIN_ID ?? 1001

export const REWARD_TOKEN =
  TOKEN_TYPES_OBJECT[window.CONFIG?.REWARD_TOKEN_SYMBOL ?? "RRT"]

export const VOTING_TOKEN = Object.assign({}, REWARD_TOKEN, {
  symbol: `v${REWARD_TOKEN.symbol}`,
  decimals: 18,
})

export const REWARD_TOKEN_TOTAL_AMOUNT =
  window.CONFIG?.REWARD_TOKEN_TOTAL_AMOUNT ?? 1000000000

export const LIQUIDITY_TOKEN = {
  name: "Liquidity",
  chainId: CHAIN_ID,
  symbol: "LIQ",
  decimals: 18,
  address: "",
  logoURI: "",
}

export const ALT_TOKEN = {
  KSP: {
    name: "KLAYswap Protocol",
    chainId: CHAIN_ID,
    symbol: "KSP",
    decimals: 18,
    address: "",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/8296.png",
  },
  SKLAY: {
    name: "sKLAY",
    chainId: CHAIN_ID,
    symbol: "SKLAY",
    decimals: 18,
    address: "",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/7881.png",
  },
  USDT: {
    name: "USD Token",
    chainId: CHAIN_ID,
    symbol: "USDT",
    decimals: 18,
    address: "0x2F20C2B88e2cB8a17d299370ecb11742795D8D2d",
    logoURI: "/icons/tokens/token_RRUSDT.svg",
  },
}

export const FIXED_DECIMALS_NUMBER = 6

export const DEPOSIT_WITHDRAW_HIDDEN_TOKENS = [SMART_CONTRACT_ADDRESS.WKLAY]

export const ERC_STD = {
  ERC20: 1,
  ERC721: 2,
  ERC1155: 3,
}

export const BASE_TOKEN_ADDRESS =
  CHAIN_ID === CHAIN.TESTNET
    ? TOKEN_TYPES_OBJECT["RRKLAY"]?.address
    : SMART_CONTRACT_ADDRESS.WKLAY
