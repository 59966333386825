import { WALLET_PROVIDERS } from "const/walletProviders"
import { prepare } from "klip-sdk"
import { encryptAddress } from "utils"

const DEBUG_WALLET =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEBUG_WALLET
    : undefined

const KLIP_APP_NAME =
  window.CONFIG?.KLIP_APP_NAME || process.env.REACT_APP_KLIP_APP_NAME
const LOCAL_STORAGE_KEYS = {
  WALLET_PROVIDER: "wallet-provider",
  WALLET_ADDRESS: "wallet-address",
}

export const logoutWallet = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.WALLET_PROVIDER)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.WALLET_ADDRESS)
}

export const klipConnect = async () => {
  return await prepare.auth({
    bappName: KLIP_APP_NAME,
  })
}

export const getKlipRequestURL = (reqKey) => {
  if (!reqKey) return
  return `https://klipwallet.com/?target=/a2a?request_key=${reqKey}`
}

export const getKlipRequestIntent = (reqKey) => {
  if (!reqKey) return
  return `intent://klipwallet/open?url=${getKlipRequestURL(
    reqKey
  )}#Intent;scheme=kakaotalk;package=com.kakao.talk;end`
}

export const getKlipRequestForSafari = (reqKey) => {
  if (!reqKey) return
  return `kakaotalk://klipwallet/open?url=${getKlipRequestURL(reqKey)}`
}

export const getKlipAppStoreIntent = () => {
  return "itms-apps://itunes.apple.com/app/id362057947"
}

export const kaikasConnect = async () => {
  const kaikasWallet = await getKaikasWallet()
  if (kaikasWallet == null) {
    return logoutWallet()
  }
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.WALLET_PROVIDER,
    WALLET_PROVIDERS.KAIKAS.NAME
  )
  return kaikasWallet
}

export const getKaikasWallet = async () => {
  try {
    const klaytn = window.klaytn
    const isSDKInstalled = typeof klaytn !== "undefined"
    if (!isSDKInstalled) return

    const accounts = await klaytn.enable()
    klaytn.selectedAddress = accounts[0]
    return accounts[0]
  } catch (err) {
    console.error(err)
    return
  }
}

export const loadExistWallet = async () => {
  const savedWallet = localStorage.getItem(LOCAL_STORAGE_KEYS.WALLET_PROVIDER)
  switch (savedWallet) {
    case WALLET_PROVIDERS.KAIKAS.NAME: {
      const kaikasWallet = DEBUG_WALLET || (await getKaikasWallet())
      if (kaikasWallet == null) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.WALLET_PROVIDER)
        return undefined
      }
      return {
        provider: WALLET_PROVIDERS.KAIKAS.NAME,
        address: kaikasWallet,
      }
    }
    // case WALLET_PROVIDERS.KLIP.NAME: {
    //   const encryptedAddress = localStorage.getItem(LOCAL_STORAGE_KEYS.WALLET_ADDRESS)
    //   if(!encryptedAddress){
    //     localStorage.removeItem(LOCAL_STORAGE_KEYS.WALLET_PROVIDER)
    //     return undefined
    //   }
    //   return {
    //     provider: WALLET_PROVIDERS.KLIP.NAME,
    //     address: decryptAddress(encryptedAddress)
    //   }
    // }
    default:
      return
  }
}

export const saveKlipWallet = (address) => {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.WALLET_PROVIDER,
    WALLET_PROVIDERS.KLIP.NAME
  )
  const _address = encryptAddress(address)
  localStorage.setItem(LOCAL_STORAGE_KEYS.WALLET_ADDRESS, _address)
}
