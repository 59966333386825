import { ROUTE_PATHS } from "./routes"
const HIDDEN_NAVIGATOR_MENU = window.CONFIG?.HIDDEN_NAVIGATOR_MENU ?? []

const NAVIGATOR_MENU_DEFAULT = [
  // {
  //   path: ROUTE_PATHS.HOME,
  //   title: "common:NavigationMenu.Home"
  // },
  {
    path: ROUTE_PATHS.ASSETS,
    title: "common:NavigationMenu.Assets",
  },
  {
    path: ROUTE_PATHS.SWAP,
    title: "common:NavigationMenu.Swap",
  },
  {
    path: ROUTE_PATHS.POOL,
    title: "common:NavigationMenu.Pool",
  },
  // {
  //   title: "common:NavigationMenu.NFTGroup",
  //   subMenu: [
  //     {
  //       path: ROUTE_PATHS.NFT_MARKET,
  //       title: "common:NavigationMenu.NFTMarket",
  //     },
  //     {
  //       path: "#",
  //       title: "common:NavigationMenu.NFTMinting",
  //     },
  //   ],
  // },
  {
    path: ROUTE_PATHS.STAKING_POOL,
    title: "common:NavigationMenu.Staking",
  },
  {
    title: "common:NavigationMenu.Voting",
    subMenu: [
      {
        title: "common:NavigationMenu.VotingPool",
        path: ROUTE_PATHS.VOTING_POOL,
      },
      {
        title: "common:NavigationMenu.VotingProposal",
        path: ROUTE_PATHS.VOTING_PROPOSAL,
      },
    ],
  },
  // {
  //   path: ROUTE_PATHS.IDO_STORE,
  //   title: "common:NavigationMenu.IDOShop",
  // },
  // {
  //   path: ROUTE_PATHS.NFT_STORE,
  //   title: "common:NavigationMenu.NFTShop",
  // },
  // {
  //   path: ROUTE_PATHS.AIRDROPS,
  //   title: "common:NavigationMenu.Airdrops",
  // },
  {
    path: ROUTE_PATHS.ANALYTICS,
    title: "common:NavigationMenu.Analytics",
  },
  // {
  //   path: ROUTE_PATHS.PRICE_CHART,
  //   title: "common:NavigationMenu.PriceChart",
  // },
]

export const NAVIGATOR_MENU = {
  EN: NAVIGATOR_MENU_DEFAULT.filter(
    (ele) => !HIDDEN_NAVIGATOR_MENU.includes(ele.path)
  ),
  KO: NAVIGATOR_MENU_DEFAULT.filter(
    (ele) => !HIDDEN_NAVIGATOR_MENU.includes(ele.path)
  ),
}

const HELPER_NAVIGATION_MENU_EN = {
  subMenu: [
    {
      directLink: true,
      path: "https://round-robin.gitbook.io/new-collection/v/english/",
      title: "common:NavigationMenu.Documents",
    },
    {
      directLink: true,
      path: "https://twitter.com/RoundRobin_DeFi",
      title: "common:NavigationMenu.Twitter",
    },
    {
      directLink: true,
      path: "https://medium.com/@roundrobin_defi",
      title: "common:NavigationMenu.Medium",
    },
    {
      directLink: true,
      path: "https://t.me/roundrobin_official",
      title: "common:NavigationMenu.Telegram",
    },
    // {
    //   directLink: true,
    //   path: "https://ducato.io",
    //   title: "common:NavigationMenu.About",
    // }
  ],
  title: "common:NavigationMenu.Help",
}

const HELPER_NAVIGATION_MENU_KO = {
  subMenu: [
    {
      directLink: true,
      path: "https://round-robin.gitbook.io/new-collection/",
      title: "common:NavigationMenu.Documents",
    },
    {
      directLink: true,
      path: "https://twitter.com/RoundRobin_DeFi",
      title: "common:NavigationMenu.Twitter",
    },
    {
      directLink: true,
      path: "https://medium.com/@roundrobin_defi",
      title: "common:NavigationMenu.Medium",
    },
    {
      directLink: true,
      path: "https://t.me/roundrobin_official",
      title: "common:NavigationMenu.Telegram",
    },
    // {
    //   directLink: true,
    //   path: "https://ducato.io",
    //   title: "common:NavigationMenu.About",
    // }
  ],
  title: "common:NavigationMenu.Help",
}

export const HELPER_NAVIGATION_MENU = {
  EN: HELPER_NAVIGATION_MENU_EN,
  KO: HELPER_NAVIGATION_MENU_KO,
}
