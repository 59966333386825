import { Button, Col, Divider, Row, Space, Typography } from "antd"
import Modal from "antd/lib/modal/Modal"
import {
  DISABLED_WALLET_PROVIDER,
  WALLET_PROVIDERS,
} from "const/walletProviders"
import { AppContext } from "index"
import React, { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { DISPATCH_TYPE } from "services/hooks/Utils/useAppState"
import AccountWallet from "./AccountWallet"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"

const ConnectWalletModal = ({ isModalVisible, handleCancel }) => {
  const { t } = useTranslation()
  const [selectedWallet, setSelectedWallet] = useState()
  const [walletProps, dispatcher] = useContext(AppContext)

  const supportedWallet = useMemo(() => {
    try {
      return Object.values(WALLET_PROVIDERS)
        .filter((ele) => !DISABLED_WALLET_PROVIDER.includes(ele.NAME))
        .filter((ele) => !isMobile || ele.NAME !== WALLET_PROVIDERS.KAIKAS.NAME)
    } catch (err) {
      return Object.values(WALLET_PROVIDERS).filter(
        (ele) => !isMobile || ele.NAME !== WALLET_PROVIDERS.KAIKAS.NAME
      )
    }
  }, [])

  const onChangeWallet = () => {
    setSelectedWallet()
    dispatcher({
      type: DISPATCH_TYPE.CHANGE_WALLET,
    })
  }

  return (
    <Modal
      title={
        walletProps?.wallet
          ? t("AccountDetail.Title")
          : t("ConnectWallet.Title")
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      width="fit-content"
      className={selectedWallet?.NAME === "Klip" ? "klip-connect-modal" : null}
    >
      {walletProps?.wallet ? (
        <Space
          id="connect-wallet-modal"
          size={30}
          direction="vertical"
          className="space-align-center space-full-width"
        >
          <AccountWallet />
          <Button type="ghost" onClick={onChangeWallet}>
            {t("ConnectWallet.Modal.ChangeWallet")}
          </Button>
        </Space>
      ) : selectedWallet ? (
        <Space
          id="connect-wallet-modal"
          direction="vertical"
          className="space-align-center space-full-width"
        >
          <selectedWallet.DETAIL_COMPONENT />
          <Divider />
          <Button type="ghost" onClick={onChangeWallet}>
            {t("ConnectWallet.Modal.ChangeWallet")}
          </Button>
        </Space>
      ) : (
        <Space
          id="connect-wallet-modal"
          direction="vertical"
          className="space-full-width"
          size={30}
        >
          <Typography.Text className="connect-wallet-title">
            {t("ConnectWallet.Modal.Description")}
          </Typography.Text>
          <Space className="full-width" direction="vertical" size="small">
            {supportedWallet.map((wallet, i, arr) => (
              <Space
                key={wallet.NAME}
                direction="vertical"
                className="full-width"
                size={10}
              >
                <Typography.Text>{t(wallet.TITLE)}</Typography.Text>
                <div
                  className="wallet-connect-btn full-width"
                  onClick={() => setSelectedWallet(wallet)}
                >
                  <Row justify="center" gutter={20}>
                    <Col className="align-self-center">
                      <img src={wallet.ICON} alt={wallet.NAME} />
                    </Col>
                    <Col className="align-self-center">
                      <Typography>{t(wallet.NAME)}</Typography>
                    </Col>
                  </Row>
                </div>
                {arr.length - 1 > i ? (
                  <Divider plain>{t("Or").toUpperCase()}</Divider>
                ) : null}
              </Space>
            ))}
          </Space>
        </Space>
      )}
    </Modal>
  )
}

ConnectWalletModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

export default ConnectWalletModal
