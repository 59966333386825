import Caver from "caver-js"
import { ENDPOINT_NODE_ADDRESS } from "const/smartContracts"
import { AppContext } from "index"
import { useCallback, useContext, useEffect, useMemo } from "react"
import { WALLET_PROVIDERS } from "const/walletProviders"
import pLimit from "p-limit"

const limit = pLimit(1)
let _caver = new Caver(ENDPOINT_NODE_ADDRESS)
let _currentProvider = ENDPOINT_NODE_ADDRESS

const useCaver = (retry = 5) => {
  const currentProvider = useCurrentProvider()

  const mkClient = useCallback((currentProvider) => {
    return limit(() => {
      if (_caver != null && _currentProvider == currentProvider) return
      try {
        _caver = new Caver(currentProvider)
        _currentProvider = currentProvider
      } catch (err) {
        // console.error(err)
        _caver = undefined
        _currentProvider = undefined
      }
    }, currentProvider)
  }, [])

  // On provider change
  useEffect(() => {
    mkClient(currentProvider)
  }, [currentProvider, mkClient])

  // Default timer
  useEffect(() => {
    const loop = setInterval(() => {
      if (retry && !_caver) mkClient(currentProvider)
    }, retry * 1000)

    return () => {
      clearInterval(loop)
    }
  }, [retry, mkClient, currentProvider])

  const defaultMkClient = useCallback(() => {
    mkClient(currentProvider)
  }, [currentProvider, mkClient])

  return [_caver, defaultMkClient]
}

export default useCaver

const useCurrentProvider = () => {
  const [context] = useContext(AppContext)
  const provider = useMemo(() => {
    switch (context?.wallet?.provider) {
      case WALLET_PROVIDERS.KAIKAS.NAME:
        return window.klaytn ?? ENDPOINT_NODE_ADDRESS
      default:
        return ENDPOINT_NODE_ADDRESS
    }
  }, [context?.wallet?.provider])

  return provider
}
