import React, { useEffect, useMemo, useState } from "react"
import { Button, Col, Divider, Drawer, Row, Space, Typography } from "antd"
import { ROUTE_PATHS } from "const/routes"
import DarkModeSwitch from "./components/DarkModeSwitch"
import LanguageSelector from "./components/LanguageSelector"
import NavigationMenu from "./components/NavigatorMenu"
import SystemIndicator from "./components/SystemIndicator"
import WalletIndicator from "./components/WalletIndicator/"
import "./header.less"
import { Link } from "react-router-dom"
import { useBigScreen } from "utils/responsive"
import { MenuOutlined } from "@ant-design/icons"
import { useThemeSwitcher } from "react-css-theme-switcher"
import { usePortrait } from "utils/responsive"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { CONTACT_EMAIL } from "screens/Home/components/Contact"
import DucatoLogo from "assets/icons/logo_roundrobin.svg"
import DucatoLogoWhite from "assets/icons/logo_roundrobin_white.svg"
import HelperMenu from "components/Header/components/HelperMenu"
import { removeParamsFromPath } from "utils"
import _ from "lodash"

const Header = () => {
  const normalView = useBigScreen(1144)
  const portraitView = usePortrait()
  const { t } = useTranslation()
  const location = useLocation()
  const isNormalLogo = useMemo(() => {
    const normalPaths = [
      ROUTE_PATHS.HOME,
      ROUTE_PATHS.PRICE_CHART,
      ROUTE_PATHS.NFT_MARKET,
    ]
    const strippedIDPaths = [
      ROUTE_PATHS.NFT_DETAIL1155,
      ROUTE_PATHS.NFT_DETAIL721,
      ROUTE_PATHS.NFT_COLLECTION_DETAIL,
    ].map(removeParamsFromPath)

    if (normalPaths.includes(location.pathname)) return true
    return strippedIDPaths.some((path) => location.pathname.includes(path))
  }, [location?.pathname])
  const { currentTheme } = useThemeSwitcher()

  const [visible, setVisible] = useState(false)
  const showDrawer = () => {
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }

  const [isOpaque, setIsOpaque] = useState(false)

  useEffect(() => {
    const onScroll = _.throttle((e) => {
      setIsOpaque(e.target.documentElement.scrollTop >= 50)
    }, 100)
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const MobileTitleMenu = useMemo(() => {
    return (
      <Row
        id="main-menu-drawer"
        className="mobile-menu-title full-width"
        justify="space-between"
      >
        <Col style={{ display: "flex" }}>
          <img
            src={
              currentTheme === "light"
                ? "/icons/close-btn.svg"
                : "/icons/close-btn-dark.svg"
            }
            alt="Close button"
            height={16}
            onClick={onClose}
            style={{ margin: "auto" }}
          />
        </Col>
        <Col flex>
          <Space size={5}>
            <SystemIndicator type="drawer" />
            <DarkModeSwitch />
            <LanguageSelector />
          </Space>
        </Col>
      </Row>
    )
  }, [currentTheme])

  // PC Header
  if (normalView)
    return (
      <Space
        direction="vertical"
        size={0}
        className={`full-width bar ${isOpaque ? "header-opaque" : ""}`}
      >
        <Row justify="end">
          <Col>
            <Space size="large">
              <HelperMenu />
              <SystemIndicator />
              <DarkModeSwitch />
              <LanguageSelector />
            </Space>
          </Col>
        </Row>
        <Divider className="no-margin" />
        <Row className="navigation-bar" align="middle">
          <Col>
            <Link to={ROUTE_PATHS.HOME} className="logo-holder">
              <img
                src={
                  currentTheme !== "dark" && (isNormalLogo || isOpaque)
                    ? DucatoLogo
                    : DucatoLogoWhite
                }
                alt="Logo"
                height={30}
              />
            </Link>
          </Col>
          <Col flex={1}>
            <NavigationMenu />
          </Col>
          <Col>
            <WalletIndicator />
          </Col>
        </Row>
      </Space>
    )

  // Mobile Header
  return (
    <Row
      className={isOpaque ? "bar mobile header-opaque" : "bar mobile"}
      justify="space-between"
      align="middle"
    >
      <Col>
        <Link to={ROUTE_PATHS.HOME} className="logo-link">
          <img
            src={
              currentTheme !== "dark" && (isNormalLogo || isOpaque)
                ? DucatoLogo
                : DucatoLogoWhite
            }
            alt="Logo"
            height={30}
          />
        </Link>
      </Col>
      <Col>
        <Space className="menu-btn" size={0}>
          <WalletIndicator />
          <Button
            type="ghost"
            icon={<MenuOutlined />}
            onClick={showDrawer}
          ></Button>
          <Drawer
            title={MobileTitleMenu}
            forceRender
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            width={portraitView ? "100vw" : "fit-content"}
          >
            <Space
              direction="vertical"
              className="full-width mobile-navigator"
              size={20}
            >
              <NavigationMenu mode="inline" onSelect={onClose} withHelper />
              <Divider />
              <Typography.Text type="secondary">
                {CONTACT_EMAIL}
              </Typography.Text>
              <Typography.Text type="secondary">
                {t("Footer.Copyright")}
              </Typography.Text>
            </Space>
          </Drawer>
        </Space>
      </Col>
    </Row>
  )
}

const HeaderWrapper = () => {
  const location = useLocation()
  const isNormal = useMemo(() => {
    const normalPaths = [
      ROUTE_PATHS.HOME,
      ROUTE_PATHS.PRICE_CHART,
      ROUTE_PATHS.NFT_MARKET,
    ]
    const strippedIDPaths = [
      ROUTE_PATHS.NFT_DETAIL1155,
      ROUTE_PATHS.NFT_DETAIL721,
      ROUTE_PATHS.NFT_COLLECTION_DETAIL,
    ].map(removeParamsFromPath)

    if (normalPaths.includes(location.pathname)) return true
    return strippedIDPaths.some((path) => location.pathname.includes(path))
  }, [location])
  return (
    <div className={isNormal ? "header" : "header white-accent"} id="header">
      <Header />
    </div>
  )
}

export default HeaderWrapper
