import { WORKER_API_ROUTE } from "const/api"
import { TOKEN_TYPES, TOKEN_TYPES_OBJECT } from "const/token"
import moment from "moment"
import axios from "utils/axios"
import { isSameAddress, numberOrDefault } from "utils/number"

export const getPriceChart = async (
  [tokenA, tokenB],
  [startTime, endTime, interval]
) => {
  return axios.get(WORKER_API_ROUTE.PRICE_HISTORY, {
    params: {
      startTime,
      endTime,
      interval,
      tokenA,
      tokenB,
    },
  })
}

const getPriceChartData = async ([token0, token1], [start, end, _interval]) => {
  if (token0 == null || token1 == null || start == null || end == null)
    return []
  const interval = intervalMapping(_interval)
  const resolution = PRICE_CHART_INTERVAL_OPTIONS[interval]
  const queryRanges = generateTimeRange(start, end, resolution)
  const _dataList = await Promise.all(
    queryRanges.map(async ([startQuery, endQuery]) => {
      try {
        const response = await getPriceChart(
          [token0, token1],
          [startQuery, endQuery, interval]
        )
        if (response == null || response.status !== 200) return []
        return response.data.histories.map((ele) => ({
          ...ele,
          time: ele.timestamp,
        }))
      } catch (err) {
        return []
      }
    })
  )
  return _dataList.flatMap((ele) => ele)
}

const configurationData = {
  supported_resolutions: ["5", "15", "30", "60", "180", "1d", "1w"],
  exchanges: [
    {
      value: "RoundRobin",
      name: "RoundRobin",
      desc: "RoundRobin",
    },
  ],
  symbols_types: [
    {
      name: "crypto",
      // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
      value: "crypto",
    },
  ],
}

// Generate a symbol ID from a pair of the coins
const generateSymbol = (exchange, fromSymbol, toSymbol) => {
  const short = `${fromSymbol}/${toSymbol}`
  return {
    short,
    full: `${exchange}:${short}`,
  }
}

export const parseFullSymbol = (fullSymbol) => {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/)
  if (!match) {
    return null
  }

  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] }
}

const getAllSymbols = () => {
  return TOKEN_TYPES.flatMap((token0) => {
    return TOKEN_TYPES.filter(
      (token) => !isSameAddress(token0.address, token.address)
    ).map((token1) => {
      const symbol = generateSymbol(
        configurationData.exchanges[0].value,
        token0.symbol,
        token1.symbol
      )
      return {
        symbol: symbol.short,
        full_name: symbol.full,
        description: symbol.short,
        exchange: configurationData.exchanges[0].value,
        type: "crypto",
      }
    })
  })
}

export class PriceChartDatafeed {
  constructor(options = {}) {
    this.options = options
  }

  onReady(callback) {
    console.log("[onReady]: Method call")
    callback(configurationData)
  }

  async resolveSymbol(
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) {
    console.log("[resolveSymbol]: Method call", symbolName)
    const symbols = await getAllSymbols()
    const symbolItem = symbols.find(({ full_name }) => full_name === symbolName)
    if (!symbolItem) {
      console.log("[resolveSymbol]: Cannot resolve symbol", symbolName)
      onResolveErrorCallback("cannot resolve symbol")
      return
    }
    const token0 =
      TOKEN_TYPES_OBJECT[parseFullSymbol(symbolItem.full_name).fromSymbol]
    const token1 =
      TOKEN_TYPES_OBJECT[parseFullSymbol(symbolItem.full_name).toSymbol]

    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: `${token0?.name}/${token1?.name}`,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 1000000,
      has_intraday: true,
      has_weekly_and_monthly: true,
      supported_resolutions: configurationData.supported_resolutions,
      data_status: "streaming",
      visible_plots_set: "ohlcv",
      volume_precision: 2,
    }

    console.log("[resolveSymbol]: Symbol resolved", symbolName)
    onSymbolResolvedCallback(symbolInfo)
  }

  async getBars(
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) {
    const { from, to } = periodParams
    console.log("[getBars]: Method call", symbolInfo, resolution, from, to)
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name)
    const [token0, token1] = [
      TOKEN_TYPES_OBJECT[parsedSymbol.fromSymbol]?.address,
      TOKEN_TYPES_OBJECT[parsedSymbol.toSymbol]?.address,
    ]

    try {
      const resp = await getPriceChartData(
        [token0, token1],
        [from, to, resolution]
      )

      if (resp?.length === 0) {
        onHistoryCallback([], { noData: true })
        return
      }

      const bars = resp.map(barValidator).filter((ele) => ele != null)

      console.log(`[getBars]: returned ${bars.length} bar(s)`)
      onHistoryCallback(bars, { noData: false })
    } catch (error) {
      console.log("[getBars]: Get error", error)
      onErrorCallback(error)
    }
  }

  async searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    console.log("[searchSymbols]: Method call")
    const symbols = await getAllSymbols()
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1
      return isExchangeValid && isFullSymbolContainsInput
    })
    onResultReadyCallback(newSymbols)
  }

  async subscribeBars(...args) {
    console.log("[subscribeBars]: Method call ", args)
  }

  async unsubscribeBars(...args) {
    console.log("[unsubscribeBars]: Method call ", args)
  }
}

const generateTimeRange = (start, end, interval) => {
  try {
    if (end < start) return []
    const gap = parseInt(end) - parseInt(start)
    const rangeLength = parseInt(interval) * DATA_POINTS_LIMIT
    if (rangeLength > gap) return [[start, end]]
    const rangeCount = Math.floor(gap / rangeLength)
    return [...Array(rangeCount).keys()].map((i) => {
      const invert = rangeCount - i - 1
      return [end - invert * rangeLength, end - (invert - 1) * rangeLength - 1]
    })
  } catch (err) {
    console.log(err)
    return []
  }
}

const DATA_POINTS_LIMIT = 100 // 100 is query limit

const intervalMapping = (interval) => {
  switch (interval) {
    case "5":
      return "5m"
    case "15":
      return "5m"
    case "30":
      return "30m"
    case "60":
      return "1h"
    case "180":
      return "3h"
    case "1D":
      return "1d"
    case "1W":
      return "1w"
    default:
      return interval
  }
}

export const PRICE_CHART_INTERVAL_OPTIONS = {
  "5m": 5 * 60,
  "15m": 15 * 60,
  "30m": 30 * 60,
  "1h": 60 * 60,
  "3h": 3 * 60 * 60,
  "1d": 24 * 60 * 60,
  "1w": 7 * 24 * 60 * 60,
}

export const barValidator = (bar) => {
  if (moment.unix(bar.timestamp).isAfter(moment())) return undefined

  return {
    time: bar.timestamp * 1000,
    low: numberOrDefault(bar.low, numberOrDefault(bar.close)),
    high: numberOrDefault(bar.high, numberOrDefault(bar.open)),
    open: numberOrDefault(bar.open),
    close: numberOrDefault(bar.close),
    volume: numberOrDefault(bar.volume),
  }
}
