import { WORKER_API_ROUTE } from "const/api"
import axios from "utils/axios"

export const fetchVotingProposalList = async (query, options = {}) => {
  const { name, type, tags } = query ?? {}
  return await axios.get(WORKER_API_ROUTE.LIST_PROPOSAL, {
    ...options,
    params: {
      name,
      type,
      tags,
    },
  })
}

export const fetchVotingProposalDetail = async (id, options = {}) => {
  return await axios.get(WORKER_API_ROUTE.PROPOSAL_DETAIL.replace(":id", id), {
    ...options,
  })
}

export const fetchVotingProposalChoices = async (id, options = {}) => {
  return await axios.get(
    WORKER_API_ROUTE.PROPOSAL_VOTE_DETAIL.replace(":id", id),
    {
      ...options,
    }
  )
}
