import React, { useState } from "react"
import { WALLET_PROVIDERS } from "const/walletProviders"
import { AppContext } from "index"
import { useCallback, useContext, useEffect } from "react"
import { DISPATCH_TYPE } from "services/hooks/Utils/useAppState"
import { kaikasConnect } from "services/wallet-providers"
import { Button, Space, Typography } from "antd"
import KlayIcon from "assets/icons/ic-klay.svg"
import SpinContainer from "components/SpinContainer"
import { useTranslation } from "react-i18next"
import { useHash } from "react-use"

const KaikasWalletConnect = () => {
  const { t } = useTranslation()
  const [, dispatcher] = useContext(AppContext)
  const [error, setError] = useState(false)
  const [, setHash] = useHash()

  const isSDKInstalled = typeof window.klaytn !== "undefined"

  const kaikasConnectHandler = useCallback(async () => {
    setError(false)
    const kaikasWallet = await kaikasConnect()

    if (kaikasWallet) {
      setHash("")
      return dispatcher({
        type: DISPATCH_TYPE.CONNECT_WALLET,
        payload: {
          provider: WALLET_PROVIDERS.KAIKAS.NAME,
          address: kaikasWallet,
        },
      })
    } else setError(true)
  }, [dispatcher, setHash])

  useEffect(() => {
    if (isSDKInstalled) kaikasConnectHandler()
  }, [isSDKInstalled, kaikasConnectHandler])

  return (
    <Space
      id="connect-wallet-modal"
      direction="vertical"
      className="space-align-center space-full-width"
    >
      <Space>
        <img src={KlayIcon} alt="Kaikas logo" />
        <Typography.Text>
          {t("ConnectWallet.Wallet.Kaikas.Title")}
        </Typography.Text>
      </Space>
      {isSDKInstalled ? (
        error ? (
          <Space direction="vertical">
            <Typography.Text type="danger">
              {t("ConnectWallet.Wallet.Kaikas.Error")}
            </Typography.Text>
            <Button onClick={kaikasConnectHandler} size="small">
              {t("ConnectWallet.Wallet.Kaikas.Retry")}
            </Button>
          </Space>
        ) : (
          <SpinContainer />
        )
      ) : (
        <>
          <Typography.Link
            href={WALLET_PROVIDERS.KAIKAS.INSTALL_URL}
            target="_blank"
          >
            {t("ConnectWallet.Wallet.Kaikas.Install")}
          </Typography.Link>
          <Typography.Text>
            {t("ConnectWallet.Wallet.Kaikas.Hint")}
          </Typography.Text>
        </>
      )}
    </Space>
  )
}

export default KaikasWalletConnect
